import styles from "./styles.module.scss";
import { useState } from "react";
import { connect } from "react-redux";

import * as Actions from "../../store/actions";
import { translate, availableLanguages } from "../../translation";
import { AuthPropsTypes } from "../types";
import { StateTypes } from "../../store/types";
import Logo from "../../assets/logo-doc.png";
//UI
import Layout from "antd/lib/layout";
import Dropdown from "antd/lib/dropdown";
import Space from "antd/lib/space";
import Alert from "antd/lib/alert";
import type { MenuProps } from "antd/es/menu/menu";
//Icons
import CaretDownOutlined from "@ant-design/icons/CaretDownOutlined";

const { Header, Sider, Content } = Layout;

function AuthLayout(props: AuthPropsTypes) {
  const [language, setLanguage] = useState("Українська");

  const intl = (selector: string) => {
    return translate(selector, props.lang ?? "uk");
  };

  const items: MenuProps["items"] = availableLanguages;

  const onClick: MenuProps["onClick"] = ({ key }) => {
    const language = availableLanguages.find(
      (lang) => lang.key === Number(key)
    );
    if (language) {
      props.changeLanguage(language.code);
      setLanguage(language.label);
    }
  };

  return (
    <Layout className={styles["main-layout"]}>
      <Sider className={styles["sider"]}>
        <div className={styles["sider-content"]}>
          <a href="." className={styles["sider-link"]}>
            {intl("terms-link")}
          </a>
        </div>
      </Sider>
      <Layout>
        <Header className={styles["header"]}>
          <Dropdown className={styles["dropdown"]} menu={{ items, onClick }}>
            <a href="." onClick={(e) => e.preventDefault()}>
              <Space>
                {language}
                <CaretDownOutlined />
              </Space>
            </a>
          </Dropdown>
        </Header>
        <Content className={styles["content"]}>
          <img src={Logo} alt="Logo" /* className={styles["image"]} */ />
          {props.message ? (
            <Alert
              // className={styles["auth-alert"]}
              message={intl(props.message)}
              type="error"
              onClose={props.closeMessage}
              banner
              closable
            />
          ) : null}
          {props.children}
        </Content>
      </Layout>
    </Layout>
  );
}

function mapStateToProps(state: StateTypes) {
  return {
    lang: state.lang?.value,
    message: state.authLayout?.value,
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    changeLanguage: (language: string) =>
      dispatch({ type: Actions.CHANGE_LANGUAGE, language }),
    closeMessage: () => dispatch({ type: Actions.CLOSE_MESSAGE }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AuthLayout);
