import { connect } from "react-redux";

import MainLayout from "../../hoc/main-layout";
import * as Actions from "../../store/actions";
import { translate } from "../../translation";
import { PropsTypes } from "../types";
import { StateTypes } from "../../store/types";
import { administrationData } from "../../components/ui/list/utils";
import { TranslationProvider } from "../../contexts/translation/index.context";
//Components
import List from "../../components/ui/list";
//UI
import Space from "antd/lib/space";

function Administration(props: PropsTypes) {
  return (
    <MainLayout activeLink={window.location.pathname}>
      <TranslationProvider lang={props.lang}>
        <Space>
          <Space direction="vertical">
            <List data={administrationData} />
          </Space>
        </Space>
      </TranslationProvider>
    </MainLayout>
  );
}

function mapStateToProps(state: StateTypes) {
  return {
    lang: state.lang?.value,
    message: state.authLayout?.value,
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    changeLanguage: (language: string) =>
      dispatch({ type: Actions.CHANGE_LANGUAGE, language }),
    raiseMessage: (message: string) =>
      dispatch({ type: Actions.RAISE_MESSAGE, message }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Administration);
