// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_space__n4ZgK {
  width: 100%;
}
.styles_space__n4ZgK .ant-space-item:first-child {
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/components/forms/directories/utils/form-items/administration/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;AACF;AACI;EACE,WAAA;AACN","sourcesContent":[".space {\r\n  width: 100%;\r\n  :global {\r\n    .ant-space-item:first-child {\r\n      width: 100%;\r\n    }\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"space": `styles_space__n4ZgK`
};
export default ___CSS_LOADER_EXPORT___;
