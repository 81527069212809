import styles from "./styles.module.scss";
import { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { ModalContext } from "../../../contexts/modal/index.context";
import { TableContext } from "../../../contexts/table/index.context";
import { request } from "../../../services";
import { SelectProps } from "./types";
import { MergedColumnType } from "../table/utils/types";
//UI
import Select from "antd/lib/select";

export default function SelectComponent(props: SelectProps) {
  const location = useLocation();
  const { setRefresh } = useContext(ModalContext);
  const { setNumenclatureId } = useContext(TableContext);
  const [selectData, setSelectData] = useState<MergedColumnType[]>([]);

  const options = selectData.map((item) => ({
    value: item.id,
    label: item.Name,
  }));

  const fetchData = async () => {
    const content = (
      await request(`${props.url || location.pathname}/getnomenclature`, {
        method: "get",
        cancelTokenKey: "getnomenclature",
      })
    )?.content;
    setSelectData(content || []);
  };

  const handleChange = (value: any) => {
    setNumenclatureId(value.value);
    setRefresh(true);
  };

  return (
    <Select
      className={styles["select"]}
      labelInValue
      onChange={handleChange}
      onDropdownVisibleChange={fetchData}
      options={options}
      allowClear
    />
  );
}
