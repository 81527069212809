import { createContext, useState } from "react";
import { MergedColumnType } from "../../components/ui/table/utils/types";
import { DatepickerContextValue, DatepickerProviderProps } from "./types";

export const DatepickerContext = createContext<DatepickerContextValue>({
  loading: false,
  setLoading: () => {},
  flag: false,
  setFlag: () => {},
  tableData: [],
  setTableData: () => {},
  rowData: null,
  setrowData: () => {},
});

export function DatepickerProvider({ children }: DatepickerProviderProps) {
  const [loading, setLoading] = useState(false);
  const [flag, setFlag] = useState(false);
  const [tableData, setTableData] = useState<MergedColumnType[]>([]);
  const [rowData, setrowData] = useState<MergedColumnType | null>(
    JSON.parse(sessionStorage.getItem("row_data") || "null")
  );

  return (
    <DatepickerContext.Provider
      value={{
        loading,
        setLoading,
        flag,
        setFlag,
        tableData,
        setTableData,
        rowData,
        setrowData,
      }}
    >
      {children}
    </DatepickerContext.Provider>
  );
}
