import { connect } from "react-redux";

import MainLayout from "../../hoc/main-layout";
import * as Actions from "../../store/actions";
import { PropsTypes } from "../types";
import { StateTypes } from "../../store/types";
import { DatepickerProvider } from "../../contexts/datepicker/index.context";
import { TranslationProvider } from "../../contexts/translation/index.context";
import { TableProvider } from "../../contexts/table/index.context";
//Components
import Documents from "../../components/documents";

function DocumentsPage(props: PropsTypes) {
  return (
    <MainLayout activeLink={window.location.pathname}>
      <TranslationProvider lang={props.lang}>
        <DatepickerProvider>
          <TableProvider>
            <Documents />
          </TableProvider>
        </DatepickerProvider>
      </TranslationProvider>
    </MainLayout>
  );
}

function mapStateToProps(state: StateTypes) {
  return {
    lang: state.lang?.value,
    message: state.authLayout?.value,
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    changeLanguage: (language: string) =>
      dispatch({ type: Actions.CHANGE_LANGUAGE, language }),
    raiseMessage: (message: string) =>
      dispatch({ type: Actions.RAISE_MESSAGE, message }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DocumentsPage);
