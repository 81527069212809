import * as Actions from "../actions";
import { StateTypes, ActionTypes } from "../types";

const initialState: StateTypes = { value: String() };
export default function reducer(state = initialState, action: ActionTypes) {
  switch (action.type) {
    case Actions.RAISE_MESSAGE:
      return {
        ...state,
        value: action.message || String(),
      };
    case Actions.CLOSE_MESSAGE:
      return {
        ...state,
        value: String(),
      };
    default:
      return state;
  }
}
