import { createContext, useState } from "react";
import { DrawerContextValue, DrawerProviderProps } from "./types";

export const DrawerContext = createContext<DrawerContextValue>({
  onClose: () => {
    console.warn("[DrawerContext]: used initial value for onClose method");
  },
  showDrawer: () => {
    console.warn("[DrawerContext]: used initial value for showDrawer method");
  },
  open: false,
  disabled: false,
  setDisabled: () => {},
});

export function DrawerProvider({ children }: DrawerProviderProps) {
  const [open, setOpen] = useState(false);
  const [disabled, setDisabled] = useState(false);


  const showDrawer = async () => {
    setOpen(true);
  };

  function onClose() {
    setOpen(false);
  }

  return (
    <DrawerContext.Provider value={{ open, showDrawer, onClose, disabled, setDisabled }}>
      {children}
    </DrawerContext.Provider>
  );
}
