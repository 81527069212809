import styles from "./styles.module.scss";
import { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { CheckboxGroupProps } from "./types";
import { TableContext } from "../../../contexts/table/index.context";
import { TranslationContext } from "../../../contexts/translation/index.context";
//UI
import Space from "antd/lib/space";
import Button from "antd/lib/button";
import Checkbox from "antd/lib/checkbox";
import type { CheckboxValueType } from "antd/es/checkbox/Group";

const CheckboxGroup = Checkbox.Group;

export default function CheckboxGroupComponent(props: CheckboxGroupProps) {
  const location = useLocation();
  const { setButtonStr, setCheckboxStr, setVisibleColumn } =
    useContext(TableContext);
  const { intl } = useContext(TranslationContext);

  // const [buttonStr, setButtonStr] = useState("");
  // const [checkboxStr, setCheckboxStr] = useState("");
  const [checkedList, setCheckedList] = useState<CheckboxValueType[]>([]);
  const isTypeOfDocument =
    props.url || location.pathname === "/directories/type-of-document";
  const plainOptions = isTypeOfDocument
    ? [intl("with-deleted")]
    : [
        intl("employees"),
        intl("contact-persons-of-other-enterprises"),
        intl("head"),
        intl("right-control"),
        intl("with-the-dismissed"),
      ];

  const letters = [
    "А",
    "Б",
    "В",
    "Г",
    "Д",
    "Е",
    "Є",
    "Ж",
    "З",
    "И",
    "І",
    "Ї",
    "Й",
    "К",
    "Л",
    "М",
    "Н",
    "О",
    "П",
    "Р",
    "С",
    "Т",
    "У",
    "Ф",
    "Х",
    "Ц",
    "Ч",
    "Ш",
    "Щ",
    "Ю",
    "Я",
  ];

  const onButtonClick = (letter: string) => {
    const newButtonStr = `and contact_person like '${letter}%'`;
    setButtonStr(newButtonStr);
    // fetchDataTable(`${newButtonStr} ${checkboxStr}`);
  };

  const onCheckboxGroupChange = (list: CheckboxValueType[]) => {
    let newList = [...list];
    let str = "";
    if (
      newList.includes(intl("employees")) &&
      newList.includes(intl("contact-persons-of-other-enterprises"))
    ) {
      newList = newList.filter(
        (item) =>
          item !== intl("employees") &&
          item !== intl("contact-persons-of-other-enterprises")
      );
    } else if (newList.includes(intl("employees"))) {
      str += "and typerec = 2";
    } else if (newList.includes(intl("contact-persons-of-other-enterprises"))) {
      str += " and typerec = 1";
    }
    if (newList.includes(intl("head"))) {
      str += " and is_kerivnik = 1";
    }
    if (newList.includes(intl("right-control"))) {
      str += " and is_control = 1";
    }
    if (newList.includes(intl("with-the-dismissed"))) {
      str += " and isnull(is_uvolen,0) in (1,0)";
    } /* else {
      str += " and isnull(is_uvolen,0)=0";
    } */
    /* if (isTypeOfDocument) { */
    if (isTypeOfDocument && newList.includes(intl("with-deleted"))) {
      str += "and isnull(eta_TypeDoc.del,0)=0";
      setVisibleColumn(true);
    } else {
      setVisibleColumn(false);
    }
    /* } */

    setCheckedList(newList);
    setCheckboxStr(str.trim());
    // fetchDataTable(`${buttonStr} ${str.trim()}`);
  };

  return (
    <>
      {!isTypeOfDocument && (
        <Space className={styles["space"]} style={{ gap: 4 }}>
          {letters.map((letter) => (
            <Button
              className={styles["letters"]}
              key={letter}
              onClick={() => onButtonClick(letter)}
            >
              {letter}
            </Button>
          ))}
        </Space>
      )}
      <CheckboxGroup
        className={styles["checkbox-group"]}
        options={plainOptions}
        value={checkedList}
        onChange={onCheckboxGroupChange}
      />
    </>
  );
}
