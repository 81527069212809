import * as Actions from "../actions";
import { getLanguage } from "../../translation";
import { StateTypes, ActionTypes } from "../types";

const initialState: StateTypes = { value: getLanguage() };
export default function reducer(state = initialState, action: ActionTypes) {
  switch (action.type) {
    case Actions.CHANGE_LANGUAGE: {
      const currentLanguage = getLanguage();
      localStorage.setItem("language", action.language || currentLanguage);

      return {
        value: action.language || currentLanguage,
      };
    }

    default:
      return state;
  }
}
