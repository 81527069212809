import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";

import MainLayout from "../../hoc/main-layout";
import * as Actions from "../../store/actions";
import { translate } from "../../translation";
import { PropsTypes, RouteTypes } from "../types";
import { StateTypes } from "../../store/types";
import { request } from "../../services";
// import {
//   documentCharacteristicsData,
//   hrDepartmentData,
//   generalData,
// } from "../../components/ui/list/utils";
import { TranslationProvider } from "../../contexts/translation/index.context";
//Components
import List from "../../components/ui/list";
//UI
import Space from "antd/lib/space";

function Directories(props: PropsTypes) {
  const location = useLocation();
  const [data, setData] = useState<RouteTypes[]>([]);
  const documentCharacteristicsData = data
    ?.filter((route) => route.RouteGroups === "DocCharact")
    .map((route) => route.translate);
  const hrDepartmentData = data
    ?.filter((route) => route.RouteGroups === "DepartPerson")
    .map((route) => route.translate);
  const generalData = data
    ?.filter((route) => route.RouteGroups === "General")
    .map((route) => route.translate);

  useEffect(() => {
    fetchDataList();
  }, []);

  const fetchDataList = async (id?: number, str?: string) => {
    const content = (
      await request(`${location.pathname}/getdatalist`, {
        method: "post",
        body: { str: "dirs" },
        cancelTokenKey: "getdatalist",
      })
    )?.content;
    setData(content || []);
  };
  return (
    <MainLayout activeLink={window.location.pathname}>
      <TranslationProvider lang={props.lang}>
        <Space size={"large"} style={{ alignItems: "flex-start" }}>
          <Space direction="vertical">
            <List
              title="document-characteristics"
              data={documentCharacteristicsData}
            />
          </Space>
          <Space direction="vertical">
            <List title="hr-department" data={hrDepartmentData} />
          </Space>
          <Space direction="vertical">
            <List title="general" data={generalData} />
          </Space>
        </Space>
      </TranslationProvider>
    </MainLayout>
  );
}

function mapStateToProps(state: StateTypes) {
  return {
    lang: state.lang?.value,
    message: state.authLayout?.value,
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    changeLanguage: (language: string) =>
      dispatch({ type: Actions.CHANGE_LANGUAGE, language }),
    raiseMessage: (message: string) =>
      dispatch({ type: Actions.RAISE_MESSAGE, message }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Directories);
