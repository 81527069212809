import styles from "../../../../styles.module.scss";
import { MergedColumnType } from "../../../types";

import { createColumn } from "../../utils";
//UI
import type { ColumnType } from "antd/es/table";
import Checkbox from "antd/lib/checkbox";

export const correspondentsColumns = (
  intl: (id: string) => string,
  getColumnSearchProps?: (
    dataIndex: keyof MergedColumnType
  ) => ColumnType<MergedColumnType>,
  filters?: { text: string; value: boolean }[]
) => [
  createColumn(intl("name"), "Name", 400, getColumnSearchProps, (a, b) =>
    a.Name?.localeCompare(b.Name)
  ),
  createColumn(intl("code"), "Code", undefined, getColumnSearchProps, (a, b) =>
    a.Code?.localeCompare(b.Code)
  ),
  createColumn(intl("code-okpo"), "OKPO", undefined, getColumnSearchProps, (a, b) =>
    a.OKPO?.localeCompare(b.OKPO)
  ),
  createColumn(intl("abbreviated"), "NFP", undefined, getColumnSearchProps, (a, b) =>
    a.NFP?.localeCompare(b.NFP)
  ),
  createColumn(intl("email"), "SET_ADRES", undefined, getColumnSearchProps, (a, b) =>
    a.SET_ADRES?.localeCompare(b.SET_ADRES)
  ),
  {
    title: intl("performer"),
    dataIndex: "IsExecuterDocFlow",
    key: "IsExecuterDocFlow",
    align: "center" as const,
    render: (_: any, record: MergedColumnType) => (
      <Checkbox
        className={styles["checkbox"]}
        checked={!!record.IsExecuterDocFlow}
        disabled
      />
    ),
    filters,
    onFilter: (value: any, record: MergedColumnType) =>
      !!record.IsExecuterDocFlow === value,
  },
];
