import styles from "./styles.module.scss";
import { useContext, useState } from "react";

import { ModalComponentProps } from "./types";
import { ModalContext } from "../../../contexts/modal/index.context";
import { FormContext } from "../../../contexts/form/index.context";
import { TranslationContext } from "../../../contexts/translation/index.context";
//UI
import Button from "antd/lib/button";
import Modal from "antd/lib/modal";
//Icons
import PlusOutlined from "@ant-design/icons/PlusOutlined";
import EditOutlined from "@ant-design/icons/EditOutlined";

export default function ModalComponent(props: ModalComponentProps) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const context = useContext(ModalContext);
  const { form } = useContext(FormContext);
  const { intl } = useContext(TranslationContext);
  const disabled =
    props.name !== "edit" ? context.disabled.add : context.disabled.edit;
  const isName = props.name !== "add" && props.name !== "edit";
  const icon = props.name === "add" ? <PlusOutlined /> : <EditOutlined />;

  const showModal = () => {
    if (props.name) {
      context.setActiveModal(
        props.name as "add" | "edit" | "contact-person" | "employee"
      );
    }
    setIsModalOpen(true);
    // context.setCloseFlag(false);
    // context.dispose(props.children, null, null, true);
  };

  // const handleOk = () => {
  //   context.modal.onOk?.();
  //   context.dispose(null, onFormSubmit, null, false);
  // };

  const handleOk = async () => {
    try {
      await form?.validateFields();
      setIsModalOpen(false);
      form?.submit();
      // context.modal.onOk?.();
      // context.dispose(null, null, null, false);
      // context.rowData && props.onOk?.(context.rowData);
      // context.setCloseFlag(true);
      // props.selectFiles && fetchFilesBody();
    } catch (errorInfo) {
      console.log("Failed:", errorInfo);
    }
  };

  // const handleCancel = () => {
  //   context.modal.onCancel?.();
  //   context.dispose(null, null, onFormResetFields, false);
  // };

  const handleCancel = () => {
    // context.modal.onCancel?.();
    // context.dispose(null, null, null, false);
    setIsModalOpen(false);
    // props.form?.resetFields();
  };

  // if (!context.modal.isOpen) {
  //   return null;
  // }
  return (
    <>
      <Button
        className={styles["button"]}
        onClick={showModal}
        disabled={disabled}
        icon={!isName && icon}
      >
        {isName && intl(props.name)}
      </Button>
      <Modal
        className={
          props.url === "/directories/employees-and-contact-persons"
            ? styles["modal-employees"]
            : props.isEdit
            ? styles["modal-edit-documents"]
            : styles["modal"]
        }
        open={isModalOpen /* context.modal.isOpen */}
        okText={intl("ok")}
        onOk={handleOk}
        onCancel={handleCancel}
        destroyOnClose={true}
      >
        {props.children /* context.modal.content */}
      </Modal>
    </>
  );
}
