// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_space__AmYaL,
.styles_checkbox-group__GeAmi {
  padding: 0.5rem 1rem;
}

.styles_space__AmYaL {
  flex-wrap: wrap;
}

.styles_letters__V\\+mdb:focus {
  background-color: #5abab9;
}`, "",{"version":3,"sources":["webpack://./src/components/ui/checkbox-group/styles.module.scss"],"names":[],"mappings":"AAAA;;EAEE,oBAAA;AACF;;AAEA;EACE,eAAA;AACF;;AAGA;EACE,yBAAA;AAAF","sourcesContent":[".space,\r\n.checkbox-group {\r\n  padding: 0.5rem 1rem;\r\n}\r\n\r\n.space {\r\n  flex-wrap: wrap;\r\n}\r\n\r\n\r\n.letters:focus {\r\n  background-color: #5abab9;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"space": `styles_space__AmYaL`,
	"checkbox-group": `styles_checkbox-group__GeAmi`,
	"letters": `styles_letters__V+mdb`
};
export default ___CSS_LOADER_EXPORT___;
