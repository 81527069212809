import styles from "./styles.module.scss";
import { useContext } from "react";
import { useLocation } from "react-router-dom";

import { DropdownButtonsProps } from "./types";
import { ModalContext } from "../../../contexts/modal/index.context";
import { TranslationContext } from "../../../contexts/translation/index.context";
//Components
import Modal from "../../../components/ui/modal";
import Popconfirm from "../../../components/ui/popconfirm";
import MoveDataForm from "../../../components/forms/move-data";
import SendMailForm from "../../../components/forms/send-mail";
//UI
import Dropdown from "antd/lib/dropdown";
import Button from "antd/lib/button";
import type { MenuProps } from "antd/es/menu/menu";
//Icons
import MoreOutlined from "@ant-design/icons/MoreOutlined";

export default function DropdownButtonsComponent(props: DropdownButtonsProps) {
  const location = useLocation();
  const context = useContext(ModalContext);
  const { intl } = useContext(TranslationContext);

  const items: MenuProps["items"] = [
    // {
    //   key: "1",
    //   label: (
    //     <a
    //       target="_blank"
    //       rel="noopener noreferrer"
    //       href={`${location.pathname}-view`}
    //     >
    //       {props.intl("view")}
    //     </a>
    //   ),
    // },
    {
      key: "2",
      label: <Popconfirm />,
    },
    // {
    //   key: "3",
    //   label: (
    //     <Modal okText={props.intl("ok")} name={props.intl("move")}>
    //       <MoveDataForm intl={props.intl} />
    //     </Modal>
    //   ),
    // },
    // {
    //   key: "4",
    //   label: (
    //     <Modal okText={props.intl("ok")} name={props.intl("send-mail")}>
    //       <SendMailForm intl={props.intl} />
    //     </Modal>
    //   ),
    // },
    {
      key: "5",
      label: (
        <Button className={styles["button"]} disabled={context.disabled.edit}>
          {intl("printing")}
        </Button>
      ),
    },
  ];

  return (
    <Dropdown menu={{ items }} trigger={["click"]} placement="bottomRight">
      <Button>
        <MoreOutlined />
      </Button>
    </Dropdown>
  );
}
