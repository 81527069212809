import jwtDecode from "jwt-decode";

interface DecodedUser {
  id: number;
  clerk: {
    id: number;
    firstName: string;
    middleName: string;
    lastName: string;
  }[];
  clerkId: number;
  email: string;
  login: string;
  role: { id: number; roleName: string }[];
  roleId: number;
  WebAdd: boolean;
  WebDel: boolean;
  iat: number;
  exp: number;
  accessLevel:
    | "Support"
    | "CompanyAdministrator"
    | "CompanyUser"
    | "User"
    | null;
}

interface DecodeTypes {
  exp: number;
}

export default class User {
  /**
   * @returns {string}
   */
  static get token(): string {
    return localStorage.access_token;
  }

  /**
   * @param {string} token
   */

  static store(token: string): void {
    localStorage.access_token = token;
  }

  static unset(): void {
    if (this.token) {
      localStorage.removeItem("access_token");
    }
  }

  static decode(): DecodedUser | null {
    const token = localStorage.getItem("access_token");

    if (token) {
      const decoded: DecodeTypes & DecodedUser = jwtDecode(token);

      if (decoded) {
        const length = decoded.exp.toString().length;
        const time = parseInt(Date.now().toString().slice(0, length), 10);

        if (time - decoded.exp < 0) {
          return decoded;
        }
      }

      localStorage.removeItem("access_token");
    }

    return null;
  }
}
