import { useEffect } from "react";
import User from "../../services/user";

export default function Home() {
  useEffect(() => {
    if (User.token) {
      const currentUser = User.decode();
      if (!currentUser) {
        window.location.href = "/auth";
      } else {
        window.location.href = "/information";
      }
    } else {
      window.location.href = "/auth";
    }
  }, []);

  return null;
}
