import styles from "./styles.module.css";
import { useRef, useState, MouseEvent, useEffect } from "react";
import { SplitterProps } from "./types";

export default function Splitter({
  leftComponent,
  rightComponent,
  collapsed,
}: SplitterProps) {
  const splitterRef = useRef<HTMLDivElement>(null);
  const [isDragging, setIsDragging] = useState(false);
  const [splitterPosition, setSplitterPosition] = useState(200);
  const minWidth: number = 150;
  const maxWidth: number = 250;

  useEffect(() => {
    const savedPosition = localStorage.getItem("splitter_position");
    setSplitterPosition(savedPosition ? Number(savedPosition) : 200);
  }, []);

  useEffect(() => {
    setSplitterPosition(collapsed ? 80 : 200)
  }, [collapsed]);

  // useEffect(() => {
  //   localStorage.setItem("splitter_position", String(splitterPosition));
  // }, [splitterPosition]);

  const handleMouseDown = (event: MouseEvent) => {
    event.preventDefault();
    setIsDragging(true);
  };

  const handleMouseUp = () => {
    setIsDragging(false);
    localStorage.setItem("splitter_position", String(splitterPosition));
  };

  const handleMouseMove = (event: MouseEvent) => {
    if (isDragging && splitterRef.current) {
      // const containerWidth = splitterRef.current.offsetWidth;
      const mouseX = event.clientX;
      // const newSplitterPosition = (mouseX / containerWidth) * 100;
      if (mouseX >= minWidth && mouseX <= maxWidth) {
        setSplitterPosition(mouseX);
      }
    }
  };

  return (
    <div
      className={styles["splitter-container"]}
      ref={splitterRef}
      onMouseUp={handleMouseUp}
      onMouseMove={handleMouseMove}
    >
      <div
        className={styles["splitter-left"]}
        style={{ width: `${splitterPosition}px` }}
      >
        {leftComponent}
      </div>
      <div className={styles["splitter-box"]} onMouseDown={handleMouseDown}>
        {/* You can customize the splitter appearance */}
      </div>
      <div
        className={styles["splitter-right"]}
        style={{ width: `calc(100% - ${splitterPosition}px)` }}
      >
        {rightComponent}
      </div>
    </div>
  );
}

// Example
// <Splitter
//   leftComponent={<Sider style={{ background: "blue" }}>Sider</Sider>}
//   rightComponent={
//     <Layout>
//       <Header style={{ background: "purple" }}>Header</Header>
//       <Content>{props.children}</Content>
//     </Layout>
//   }
// />
