import { createContext, useEffect, useState } from "react";
import { SessionContextValue, SessionProviderProps } from "./types";
import { useLocation } from "react-router-dom";

export const SessionContext = createContext<SessionContextValue>({
  tableState: { selectedRow: undefined, pageSize: 15, currentPage: 1 },
  setTableState: () => {},
  sessionData: {},
  setSessionData: () => {},
});

export function SessionProvider({ children, url }: SessionProviderProps) {
  const location = useLocation();
  const route = url || location.pathname;
  const [tableState, setTableState] = useState(
    JSON.parse(sessionStorage.getItem(`table_state_${route}`) ?? "{}") || {
      selectedRow: undefined,
      pageSize: 15,
      currentPage: 1,
    }
  );
  const [sessionData, setSessionData] = useState(
    JSON.parse(sessionStorage.getItem(`session_data_${route}`) ?? "{}") || {}
  );

  useEffect(() => {
    sessionStorage.setItem(`table_state_${route}`, JSON.stringify(tableState));
  }, [tableState, route]);

  useEffect(() => {
    sessionStorage.setItem(
      `session_data_${route}`,
      JSON.stringify(sessionData)
    );
  }, [sessionData, route]);

  return (
    <SessionContext.Provider
      value={{
        tableState,
        setTableState,
        sessionData,
        setSessionData,
      }}
    >
      {children}
    </SessionContext.Provider>
  );
}
