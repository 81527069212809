// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_splitter-container__Awdgz {
  display: flex;
  /* height: 100%; */
  width: 100%;
  overflow: hidden;
}

.styles_splitter-left__P88U3,
.styles_splitter-right__b3Mry {
  flex-grow: 1;
  height: 100%;
  overflow-y: auto;
}

.styles_splitter-box__dk0md {
  cursor: col-resize;
  width: 3px;
  background-color: #ccc;
}
`, "",{"version":3,"sources":["webpack://./src/components/ui/splitter/styles.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,kBAAkB;EAClB,WAAW;EACX,gBAAgB;AAClB;;AAEA;;EAEE,YAAY;EACZ,YAAY;EACZ,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,UAAU;EACV,sBAAsB;AACxB","sourcesContent":[".splitter-container {\r\n  display: flex;\r\n  /* height: 100%; */\r\n  width: 100%;\r\n  overflow: hidden;\r\n}\r\n\r\n.splitter-left,\r\n.splitter-right {\r\n  flex-grow: 1;\r\n  height: 100%;\r\n  overflow-y: auto;\r\n}\r\n\r\n.splitter-box {\r\n  cursor: col-resize;\r\n  width: 3px;\r\n  background-color: #ccc;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"splitter-container": `styles_splitter-container__Awdgz`,
	"splitter-left": `styles_splitter-left__P88U3`,
	"splitter-right": `styles_splitter-right__b3Mry`,
	"splitter-box": `styles_splitter-box__dk0md`
};
export default ___CSS_LOADER_EXPORT___;
