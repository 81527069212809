import { FormInstance } from "antd";
import { MergedColumnType } from "../../../../../../ui/table/utils/types";

export const setGeneral: Record<
  string,
  (form: FormInstance, rowData: MergedColumnType) => void
> = {
  "/directories/correspondents": (form, rowData) => {
    form.setFieldsValue({
      name: rowData.Name,
      code: rowData.Code,
      codeOkpo: rowData.OKPO,
      abbreviated: rowData.NFP,
      forEnvelope: rowData.NFP_komu,
      email: rowData.SET_ADRES,
      rightSign: rowData.ispidpis_turndoc,
      performer: rowData.IsExecuterDocFlow,
    });
  },
};
