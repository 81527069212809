import styles from "../../../../styles.module.scss";
import { MergedColumnType } from "../../../types";

import { createColumn } from "../../utils";
//UI
import type { ColumnType } from "antd/es/table";
import Checkbox from "antd/lib/checkbox";

export const professionClassifierColumns = (
  intl: (id: string) => string,
  getColumnSearchProps?: (
    dataIndex: keyof MergedColumnType
  ) => ColumnType<MergedColumnType>
) => [
  createColumn(intl("name"), "Name", undefined, getColumnSearchProps, (a, b) =>
    a.Name?.localeCompare(b.Name)
  ),
  createColumn(intl("code-kp"), "KP", undefined, getColumnSearchProps, (a, b) =>
    a.KP?.localeCompare(b.KP)
  ),
  createColumn(
    intl("code-okp"),
    "OKP",
    undefined,
    getColumnSearchProps,
    (a, b) => a.OKP?.localeCompare(b.OKP)
  ),
  createColumn(
    intl("code-etks"),
    "ETKS",
    undefined,
    getColumnSearchProps,
    (a, b) => a.ETKS?.localeCompare(b.ETKS)
  ),
];

export const jobCategoriesColumns = (
  intl: (id: string) => string,
  getColumnSearchProps?: (
    dataIndex: keyof MergedColumnType
  ) => ColumnType<MergedColumnType>
) => [
  createColumn(intl("name"), "Name", undefined, getColumnSearchProps, (a, b) =>
    a.Name?.localeCompare(b.Name)
  ),
  createColumn(intl("code"), "Code", undefined, getColumnSearchProps, (a, b) =>
    a.Code?.localeCompare(b.Code)
  ),
];

export const typesOfStructuralUnitColumns = (
  intl: (id: string) => string,
  getColumnSearchProps?: (
    dataIndex: keyof MergedColumnType
  ) => ColumnType<MergedColumnType>
) => [
  createColumn(intl("name"), "Name", undefined, getColumnSearchProps, (a, b) =>
    a.Name?.localeCompare(b.Name)
  ),
  createColumn(intl("code"), "Code", undefined, getColumnSearchProps, (a, b) =>
    a.Code?.localeCompare(b.Code)
  ),
  createColumn(
    intl("notes"),
    "Notes",
    undefined,
    getColumnSearchProps,
    (a, b) => a.Notes?.localeCompare(b.Notes)
  ),
];

export const employeesColumns = (
  intl: (id: string) => string,
  getColumnSearchProps?: (
    dataIndex: keyof MergedColumnType
  ) => ColumnType<MergedColumnType>,
  filters?: { text: string; value: boolean }[]
) => [
  createColumn(intl("fio"), "fio", 300, getColumnSearchProps, (a, b) =>
    a.fio?.localeCompare(b.fio)
  ),
  createColumn(
    intl("for-envelopes"),
    "fio_dp",
    200,
    getColumnSearchProps,
    (a, b) => a.fio_dp?.localeCompare(b.fio_dp)
  ),
  createColumn(intl("position"), "posada", 300, getColumnSearchProps, (a, b) =>
    a.posada?.localeCompare(b.posada)
  ),
  {
    title: intl("head"),
    dataIndex: "IS_KERIVNIK",
    key: "IS_KERIVNIK",
    width: 150,
    align: "center" as const,
    render: (_: any, record: MergedColumnType) => (
      <Checkbox
        className={styles["checkbox"]}
        checked={!!record.IS_KERIVNIK}
        disabled
      />
    ),
    filters,
    onFilter: (value: any, record: MergedColumnType) =>
      !!record.IS_KERIVNIK === value,
  },
  {
    title: intl("right-control"),
    dataIndex: "IS_CONTROL",
    key: "IS_CONTROL",
    width: 150,
    align: "center" as const,
    render: (_: any, record: MergedColumnType) => (
      <Checkbox
        className={styles["checkbox"]}
        checked={!!record.IS_CONTROL}
        disabled
      />
    ),
    filters,
    onFilter: (value: any, record: MergedColumnType) =>
      !!record.IS_CONTROL === value,
  },
  createColumn(
    intl("structural-unit"),
    "name_otdel",
    300,
    getColumnSearchProps,
    (a, b) => a.name_otdel?.localeCompare(b.name_otdel)
  ),
  createColumn(
    intl("work-phone"),
    "WORK_TEL",
    150,
    getColumnSearchProps,
    (a, b) => a.WORK_TEL?.localeCompare(b.WORK_TEL)
  ),
  createColumn(intl("fax"), "WORK_FAX", 150, getColumnSearchProps, (a, b) =>
    a.WORK_FAX?.localeCompare(b.WORK_FAX)
  ),
  createColumn(
    intl("mobile-phone"),
    "MOBILE_TEL",
    undefined,
    getColumnSearchProps,
    (a, b) => a.MOBILE_TEL?.localeCompare(b.MOBILE_TEL)
  ),
  createColumn(
    intl("email"),
    "WORK_E_MAIL",
    undefined,
    getColumnSearchProps,
    (a, b) => a.WORK_E_MAIL?.localeCompare(b.WORK_E_MAIL)
  ),
  createColumn(
    intl("no-cabinet"),
    "N_ROOM",
    150,
    getColumnSearchProps,
    (a, b) => a.N_ROOM?.localeCompare(b.N_ROOM)
  ),
  {
    title: intl("released"),
    dataIndex: "is_uvolen",
    key: "is_uvolen",
    width: 150,
    align: "center" as const,
    render: (_: any, record: MergedColumnType) => (
      <Checkbox
        className={styles["checkbox"]}
        checked={!!record.is_uvolen}
        disabled
      />
    ),
    filters,
    onFilter: (value: any, record: MergedColumnType) =>
      !!record.is_uvolen === value,
  },
];

export const structuralUnitsColumns = (
  intl: (id: string) => string,
  getColumnSearchProps?: (
    dataIndex: keyof MergedColumnType
  ) => ColumnType<MergedColumnType>,
  filters?: { text: string; value: boolean }[]
) => [
  {
    title: intl("structural-unit"),
    children: [
      createColumn(intl("code"), "Code", 150, getColumnSearchProps, (a, b) =>
        a.Code?.localeCompare(b.Code)
      ),
      createColumn(
        intl("type2"),
        "name_subdivision",
        undefined,
        getColumnSearchProps,
        (a, b) => a.name_subdivision?.localeCompare(b.name_subdivision)
      ),
      createColumn(
        intl("full-name"),
        "Name",
        500,
        getColumnSearchProps,
        (a, b) => a.Name?.localeCompare(b.Name)
      ),
      createColumn(
        intl("short-name"),
        "name_otdel_short",
        200,
        getColumnSearchProps,
        (a, b) => a.name_otdel_short?.localeCompare(b.name_otdel_short)
      ),
      createColumn(
        intl("refers-to"),
        "NameParent",
        300,
        getColumnSearchProps,
        (a, b) => a.NameParent?.localeCompare(b.NameParent)
      ),
    ],
  },
  {
    title: intl("reorganization"),
    children: [
      createColumn(
        intl("reorganized-into"),
        "NameReorg",
        300,
        getColumnSearchProps,
        (a, b) => a.NameReorg?.localeCompare(b.NameReorg)
      ),
    ],
  },
  {
    title: intl("other"),
    children: [
      createColumn(intl("notes"), "prim", 250, getColumnSearchProps, (a, b) =>
        a.prim?.localeCompare(b.prim)
      ),
      {
        title: intl("liquidated"),
        dataIndex: "is_liquidated",
        key: "is_liquidated",
        align: "center" as const,
        render: (_: any, record: MergedColumnType) => (
          <Checkbox
            className={styles["checkbox"]}
            checked={!!record.is_liquidated}
            disabled
          />
        ),
        filters,
        onFilter: (value: any, record: MergedColumnType) =>
          !!record.is_liquidated === value,
      },
    ],
  },
];

export const positionsColumns = (
  intl: (id: string) => string,
  getColumnSearchProps?: (
    dataIndex: keyof MergedColumnType
  ) => ColumnType<MergedColumnType>
) => [
  {
    title: intl("position"),
    children: [
      createColumn(
        intl("category"),
        "KATEGORIA",
        250,
        getColumnSearchProps,
        (a, b) => a.KATEGORIA?.localeCompare(b.KATEGORIA)
      ),
      createColumn(intl("code-kp"), "Code", 150, getColumnSearchProps, (a, b) =>
        a.Code?.localeCompare(b.Code)
      ),
      createColumn(
        intl("profession-classifier"),
        "NameProf",
        undefined,
        getColumnSearchProps,
        (a, b) => a.NameProf?.localeCompare(b.NameProf)
      ),
      createColumn(
        intl("position"),
        "Name",
        undefined,
        getColumnSearchProps,
        (a, b) => a.Name?.localeCompare(b.Name)
      ),
    ],
  },
  {
    title: intl("other"),
    children: [
      createColumn(
        intl("description"),
        "OPISAN",
        undefined,
        getColumnSearchProps,
        (a, b) => a.OPISAN?.localeCompare(b.OPISAN)
      ),
    ],
  },
];

export const employeesAndContactPersonsColumns = (
  intl: (id: string) => string,
  getColumnSearchProps?: (
    dataIndex: keyof MergedColumnType
  ) => ColumnType<MergedColumnType>,
  filters?: { text: string; value: boolean }[]
) => [
  createColumn(
    intl("full-name-pib"),
    "contact_person",
    300,
    getColumnSearchProps,
    (a, b) => a.contact_person?.localeCompare(b.contact_person)
  ),
  createColumn(
    intl("organization-structural-division"),
    "name_firm_plat",
    400,
    getColumnSearchProps,
    (a, b) => a.name_firm_plat?.localeCompare(b.name_firm_plat)
  ),
  createColumn(intl("position"), "posada", 350, getColumnSearchProps, (a, b) =>
    a.posada?.localeCompare(b.posada)
  ),
  {
    title: intl("head"),
    dataIndex: "is_kerivnik",
    key: "is_kerivnik",
    align: "center" as const,
    render: (_: any, record: MergedColumnType) => (
      <Checkbox
        className={styles["checkbox"]}
        checked={!!record.is_kerivnik}
        disabled
      />
    ),
    filters,
    onFilter: (value: any, record: MergedColumnType) =>
      !!record.is_kerivnik === value,
  },
  {
    title: intl("has-the-right-to-control"),
    dataIndex: "is_control",
    key: "is_control",
    align: "center" as const,
    render: (_: any, record: MergedColumnType) => (
      <Checkbox
        className={styles["checkbox"]}
        checked={!!record.is_control}
        disabled
      />
    ),
    filters,
    onFilter: (value: any, record: MergedColumnType) =>
      !!record.is_control === value,
  },
  {
    title: intl("employee"),
    dataIndex: "is_clerk",
    key: "is_clerk",
    align: "center" as const,
    render: (_: any, record: MergedColumnType) => (
      <Checkbox
        className={styles["checkbox"]}
        checked={!!record.is_clerk}
        disabled
      />
    ),
    filters,
    onFilter: (value: any, record: MergedColumnType) =>
      !!record.is_clerk === value,
  },
  {
    title: intl("liberated"),
    dataIndex: "is_uvolen",
    key: "is_uvolen",
    align: "center" as const,
    render: (_: any, record: MergedColumnType) => (
      <Checkbox
        className={styles["checkbox"]}
        checked={!!record.is_uvolen}
        disabled
      />
    ),
    filters,
    onFilter: (value: any, record: MergedColumnType) =>
      !!record.is_uvolen === value,
  },
];
