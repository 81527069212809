// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_button__fzK5E {
  background-color: transparent;
  border-color: transparent;
  box-shadow: none;
  color: #6e7583;
}

.styles_button__fzK5E:not(:disabled):not(.styles_ant-btn-disabled__RMJ-R):hover {
  background-color: transparent;
  border-color: transparent;
  box-shadow: none;
  color: #282d36;
}

.styles_button__fzK5E:disabled,
.styles_button__fzK5E.styles_ant-btn-disabled__RMJ-R {
  background-color: transparent;
  border-color: transparent;
}

.styles_button__fzK5E:focus-visible {
  outline: none;
}

.styles_modal__R8vDN,
.styles_modal-employees__5mc5\\+,
.styles_modal-edit-documents__nqHNE {
  top: 0px;
  width: 650px !important;
}
.styles_modal__R8vDN .ant-btn-primary,
.styles_modal-employees__5mc5\\+ .ant-btn-primary,
.styles_modal-edit-documents__nqHNE .ant-btn-primary {
  background-color: #5abab9;
}
.styles_modal__R8vDN .ant-btn-primary:hover,
.styles_modal-employees__5mc5\\+ .ant-btn-primary:hover,
.styles_modal-edit-documents__nqHNE .ant-btn-primary:hover {
  background-color: #5abab9 !important;
  color: #3f434e !important;
}

.styles_modal-employees__5mc5\\+ {
  width: auto !important;
}

.styles_modal-edit-documents__nqHNE {
  height: 100% !important;
  width: 100% !important;
}`, "",{"version":3,"sources":["webpack://./src/components/ui/modal/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,6BAAA;EACA,yBAAA;EACA,gBAAA;EACA,cAAA;AACF;;AAGA;EACE,6BAAA;EACA,yBAAA;EACA,gBAAA;EACA,cAAA;AAAF;;AAGA;;EAEE,6BAAA;EACA,yBAAA;AAAF;;AAGA;EACE,aAAA;AAAF;;AAGA;;;EAGE,QAAA;EACA,uBAAA;AAAF;AAII;;;EACE,yBAAA;AAAN;AAEI;;;EACE,oCAAA;EACA,yBAAA;AAEN;;AAGA;EACE,sBAAA;AAAF;;AAGA;EACE,uBAAA;EACA,sBAAA;AAAF","sourcesContent":[".button {\r\n  background-color: transparent;\r\n  border-color: transparent;\r\n  box-shadow: none;\r\n  color: #6e7583;\r\n  // width: 100%;\r\n}\r\n\r\n.button:not(:disabled):not(.ant-btn-disabled):hover {\r\n  background-color: transparent;\r\n  border-color: transparent;\r\n  box-shadow: none;\r\n  color: #282d36;\r\n}\r\n\r\n.button:disabled,\r\n.button.ant-btn-disabled {\r\n  background-color: transparent;\r\n  border-color: transparent;\r\n}\r\n\r\n.button:focus-visible {\r\n  outline: none;\r\n}\r\n\r\n.modal,\r\n.modal-employees,\r\n.modal-edit-documents {\r\n  top: 0px;\r\n  width: 650px !important;\r\n  // width: auto !important;\r\n  //   height: 100% !important;\r\n  :global {\r\n    .ant-btn-primary {\r\n      background-color: #5abab9;\r\n    }\r\n    .ant-btn-primary:hover {\r\n      background-color: #5abab9 !important;\r\n      color: #3f434e !important;\r\n    }\r\n  }\r\n}\r\n\r\n.modal-employees {\r\n  width: auto !important;\r\n}\r\n\r\n.modal-edit-documents {\r\n  height: 100% !important;\r\n  width: 100% !important;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": `styles_button__fzK5E`,
	"ant-btn-disabled": `styles_ant-btn-disabled__RMJ-R`,
	"modal": `styles_modal__R8vDN`,
	"modal-employees": `styles_modal-employees__5mc5+`,
	"modal-edit-documents": `styles_modal-edit-documents__nqHNE`
};
export default ___CSS_LOADER_EXPORT___;
