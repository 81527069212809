import styles from "./styles.module.scss";
import { useState } from "react";
import { connect } from "react-redux";
import { Link, useLocation } from "react-router-dom";

import AuthLayout from "../../../hoc/auth-layout";
import * as Actions from "../../../store/actions";
import { translate } from "../../../translation";
import { PropsTypes } from "../../types";
import { StateTypes } from "../../../store/types";
import { request } from "../../../services";
import { generatePassword } from "../../../services/generate-password";
//UI
import Form from "antd/lib/form";
import Input from "antd/lib/input";
import Button from "antd/lib/button";
import Typography from "antd/lib/typography";
import Checkbox from "antd/lib/checkbox";

const { Title, Text } = Typography;

function PasswordRecovery(props: PropsTypes) {
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const location = useLocation();

  const intl = (selector: string) => {
    return translate(selector, props.lang ?? "uk");
  };

  const onFinish = async (values: any) => {
    console.log("Success:", values);
    const newPassword = generatePassword();
    setLoading(true);
    await request(`${location.pathname}`, {
      method: "post",
      body: { ...values, password: newPassword },
      cancelTokenKey: "login",
    });
    setLoading(false);
  };

  const onFinishFailed = async (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <AuthLayout>
      <div>
        <div>
          <Title level={4}>{intl("password-recovery")}</Title>
          <Text>{intl("sent-new-password")}</Text>
        </div>
        <Form
          name="password recovery form"
          className={styles["form"]}
          layout="vertical"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          initialValues={{ confirm: false }}
        >
          <Form.Item
            label={intl("login")}
            name="email"
            rules={[
              { required: true, message: intl("login-required") },
              {
                pattern:
                  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                message: intl("email-pattern"),
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="confirm"
            valuePropName="checked"
            rules={[
              {
                validator: (_, value) => (
                  value ? setDisabled(false) : setDisabled(true),
                  Promise.resolve()
                ),
              },
            ]}
          >
            <Checkbox>{intl("confirm-password-change")}</Checkbox>
          </Form.Item>

          <Form.Item>
            <Button
              className={styles["button"]}
              type="primary"
              htmlType="submit"
              loading={loading}
              disabled={disabled}
            >
              {intl("change-password")}
            </Button>
          </Form.Item>

          <Form.Item>
            <Link to="/auth" className={styles["return"]}>
              {intl("return")}
            </Link>
          </Form.Item>
        </Form>
      </div>
    </AuthLayout>
  );
}

function mapStateToProps(state: StateTypes) {
  return {
    lang: state.lang?.value,
    message: state.authLayout?.value,
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    changeLanguage: (language: string) =>
      dispatch({ type: Actions.CHANGE_LANGUAGE, language }),
    closeMessage: () => dispatch({ type: Actions.CLOSE_MESSAGE }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PasswordRecovery);
