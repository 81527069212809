// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_button__P6Hc6 {
  width: 100%;
  background-color: #5abab9;
  color: #ffffff;
}

.styles_button__P6Hc6:not(:disabled):hover {
  background-color: #5abab9;
  color: #3f434e;
}

.styles_menu-button__py7RD {
  border-color: transparent;
}

.styles_modal__gDspF,
.styles_modal-with-footer__hUU8w {
  width: 650px !important;
}
.styles_modal__gDspF .ant-btn-primary,
.styles_modal-with-footer__hUU8w .ant-btn-primary {
  background-color: #5abab9;
}
.styles_modal__gDspF .ant-btn-primary:hover,
.styles_modal-with-footer__hUU8w .ant-btn-primary:hover {
  background-color: #5abab9 !important;
  color: #3f434e !important;
}

.styles_modal-with-footer__hUU8w .ant-modal-footer {
  display: flex;
  justify-content: space-between;
}

.styles_space__E4RIQ {
  width: 100%;
  margin-top: 20px;
}

.styles_space-div__Lb21l {
  display: flex;
}`, "",{"version":3,"sources":["webpack://./src/components/ui/file-menu/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,yBAAA;EACA,cAAA;AACF;;AAEA;EACE,yBAAA;EACA,cAAA;AACF;;AAEA;EACE,yBAAA;AACF;;AAEA;;EAGE,uBAAA;AAAF;AAII;;EACE,yBAAA;AADN;AAGI;;EACE,oCAAA;EACA,yBAAA;AAAN;;AAOI;EACE,aAAA;EACA,8BAAA;AAJN;;AASA;EACE,WAAA;EACA,gBAAA;AANF;;AASA;EACE,aAAA;AANF","sourcesContent":[".button {\r\n  width: 100%;\r\n  background-color: #5abab9;\r\n  color: #ffffff;\r\n}\r\n\r\n.button:not(:disabled):hover {\r\n  background-color: #5abab9;\r\n  color: #3f434e;\r\n}\r\n\r\n.menu-button {\r\n  border-color: transparent;\r\n}\r\n\r\n.modal,\r\n.modal-with-footer {\r\n  // top: 0px;\r\n  width: 650px !important;\r\n  // width: auto !important;\r\n  //   height: 100% !important;\r\n  :global {\r\n    .ant-btn-primary {\r\n      background-color: #5abab9;\r\n    }\r\n    .ant-btn-primary:hover {\r\n      background-color: #5abab9 !important;\r\n      color: #3f434e !important;\r\n    }\r\n  }\r\n}\r\n\r\n.modal-with-footer {\r\n  :global {\r\n    .ant-modal-footer {\r\n      display: flex;\r\n      justify-content: space-between;\r\n    }\r\n  }\r\n}\r\n\r\n.space {\r\n  width: 100%;\r\n  margin-top: 20px;\r\n}\r\n\r\n.space-div {\r\n  display: flex;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": `styles_button__P6Hc6`,
	"menu-button": `styles_menu-button__py7RD`,
	"modal": `styles_modal__gDspF`,
	"modal-with-footer": `styles_modal-with-footer__hUU8w`,
	"space": `styles_space__E4RIQ`,
	"space-div": `styles_space-div__Lb21l`
};
export default ___CSS_LOADER_EXPORT___;
