import CryptoJS from "crypto-js";

const secretKey = "mySecretKey";
const EXPIRATION_TIME = 7 * 24 * 60 * 60 * 1000;

export function encryptData(data: any) {
  const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(data), secretKey);
  const expirationTime = Date.now() + EXPIRATION_TIME;
  localStorage.access_route = JSON.stringify({
    data: encryptedData.toString(),
    expirationTime,
  });
}

export function decryptData() {
  const retrievedEncryptedData = localStorage.getItem("access_route");
  if (!retrievedEncryptedData) return null;

  const { data, expirationTime } = JSON.parse(retrievedEncryptedData);
  if (Date.now() > expirationTime) {
    localStorage.removeItem("access_route");
    localStorage.removeItem("access_token");
    return null;
  }

  const decryptedData = JSON.parse(
    CryptoJS.AES.decrypt(data, secretKey).toString(CryptoJS.enc.Utf8)
  );
  return decryptedData;
}
