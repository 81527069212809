// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_file-list-button__IoosE {
  background-color: #5abab9;
  border-color: #5abab9;
  color: #ffffff;
  width: 100%;
  margin-bottom: 10px;
}

.styles_file-list-button__IoosE:hover {
  background-color: #5abab9;
  border-color: #5abab9 !important;
  color: #3f434e !important;
}

.styles_doc-viewer-container__lMDnC {
  height: calc(100vh - 150px);
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/components/ui/file-editor/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,yBAAA;EACA,qBAAA;EACA,cAAA;EACA,WAAA;EACA,mBAAA;AACF;;AAEA;EACE,yBAAA;EACA,gCAAA;EACA,yBAAA;AACF;;AAEA;EACE,2BAAA;EACA,WAAA;EACA,gBAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AACF","sourcesContent":[".file-list-button {\r\n  background-color: #5abab9;\r\n  border-color: #5abab9;\r\n  color: #ffffff;\r\n  width: 100%;\r\n  margin-bottom: 10px;\r\n}\r\n\r\n.file-list-button:hover {\r\n  background-color: #5abab9;\r\n  border-color: #5abab9 !important;\r\n  color: #3f434e !important;\r\n}\r\n\r\n.doc-viewer-container {\r\n  height: calc(100vh - 150px);\r\n  width: 100%;\r\n  overflow: hidden;\r\n  display: flex;\r\n  justify-content: center;\r\n  align-items: center;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"file-list-button": `styles_file-list-button__IoosE`,
	"doc-viewer-container": `styles_doc-viewer-container__lMDnC`
};
export default ___CSS_LOADER_EXPORT___;
