import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { FormInstance } from "antd";

import { MergedColumnType } from "../../../../../ui/table/utils/types";
//Locale
import "dayjs/locale/uk";

dayjs.extend(customParseFormat);
dayjs.locale("uk");

export const setDocuments: Record<
  string,
  (form: FormInstance, rowData: MergedColumnType) => void
> = {
  "/documents/incoming": (form, rowData) => {
    form.setFieldsValue({
      documentLog: {
        value: rowData.nomenklatureId,
        label: rowData.nomenklatureName,
      },
      typeOfDocument: rowData.typeDocId,
      documentIndex: rowData.referenceId,
      numDocument: rowData.DocNum,
      fromRegisteredDocument:
        rowData.DocDate && dayjs(rowData.DocDate, "DD.MM.YYYY"),
      numOutput: rowData.Enter_no,
      fromRegisteredOutput:
        rowData.Enter_date && dayjs(rowData.Enter_date, "DD.MM.YYYY"),
      correspondent: rowData.kontragentId,
      addressed: rowData.RecipientId,
      content: rowData.Content,
      control: rowData.DateControl && dayjs(rowData.DateControl, "DD.MM.YYYY"),
      typeOfDelivery: rowData.typeSendId,
      notes: rowData.PrimDoc,
      numDelivery: rowData.MailingNum,
      numberOfPages: rowData.KolvoPages,
      numberOfApplications: rowData.KolvoEx,
      case: rowData.officialDocId,
      shelfLife: rowData.StorageLife,
      writtenOffInTheCase:
        rowData.CloseDate && dayjs(rowData.CloseDate, "DD.MM.YYYY"),
    });
  },
  "/documents/outcoming": (form, rowData) => {
    const { kontragentId_, kontragentName_ } = rowData;
    const ids = kontragentId_.split(", ").map(Number);
    const names = kontragentName_.split(", ");

    const correspondentValues = ids.map((id, index) => ({
      value: id,
      label: names[index],
    }));

    form.setFieldsValue({
      documentLog: {
        value: rowData.nomenklatureId,
        label: rowData.nomenklatureName,
      },
      typeOfDocument: rowData.typeDocId,
      documentIndex: rowData.referenceId,
      numDocument: rowData.DocNum,
      fromRegisteredDocument:
        rowData.DocDate && dayjs(rowData.DocDate, "DD.MM.YYYY"),
      correspondent: correspondentValues,
      content: rowData.Content,
      signatory: rowData.SignId,
      author: rowData.clerksId,
      typeOfDelivery: rowData.typeSendId,
      numDelivery: rowData.MailingNum,
      notes: rowData.PrimDoc,
      numberOfPages: rowData.KolvoPages,
      numberOfApplications: rowData.KolvoEx,
      case: rowData.officialDocId,
      shelfLife: rowData.StorageLife,
      writtenOffInTheCase:
        rowData.CloseDate && dayjs(rowData.CloseDate, "DD.MM.YYYY"),
    });
  },
  "/documents/internal": (form, rowData) => {
    form.setFieldsValue({
      placeOfRegistration: rowData.PlaseRegistrationId,
      documentLog: {
        value: rowData.nomenklatureId,
        label: rowData.nomenklatureName,
      },
      typeOfDocument: rowData.typeDocId,
      numDocument: rowData.DocNum,
      fromRegisteredDocument:
        rowData.DocDate && dayjs(rowData.DocDate, "DD.MM.YYYY"),
      content: rowData.Content,
      signatory: rowData.SignId,
      author: rowData.AuthorId,
      notes: rowData.PrimDoc,
      numberOfPages: rowData.KolvoPages,
      numberOfApplications: rowData.KolvoEx,
      case: rowData.officialDocId,
      shelfLife: rowData.StorageLife,
      writtenOffInTheCase:
        rowData.CloseDate && dayjs(rowData.CloseDate, "DD.MM.YYYY"),
    });
  },
};
