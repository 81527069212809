import styles from "./styles.module.scss";
import { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";

import { TranslationContext } from "../../../../../../contexts/translation/index.context";
import { FormContext } from "../../../../../../contexts/form/index.context";
import { ModalContext } from "../../../../../../contexts/modal/index.context";
import { TableContext } from "../../../../../../contexts/table/index.context";
import { SessionContext } from "../../../../../../contexts/session/index.context";
import { generatePassword } from "../../../../../../services/generate-password";
import { MergedColumnType } from "../../../../../ui/table/utils/types";
import { request } from "../../../../../../services";
//Components
import Drawer from "../../../../../ui/drawer";
import Directories from "../../../../../directories";
//UI
import Form from "antd/lib/form";
import Input from "antd/lib/input";
import Typography from "antd/lib/typography";
import Space from "antd/lib/space";
import Select from "antd/lib/select";
import Checkbox from "antd/lib/checkbox";
import Tooltip from "antd/lib/tooltip";
import Button from "antd/lib/button";
import DatePicker from "antd/lib/date-picker";
//Icons
import KeyOutlined from "@ant-design/icons/KeyOutlined";
//Locale
import "dayjs/locale/uk";

dayjs.extend(customParseFormat);
dayjs.locale("uk");

const { Title } = Typography;

export function ConfiguringPageAccess({ pathname }: { pathname: string }) {
  const location = useLocation();
  const { intl } = useContext(TranslationContext);
  const { form } = useContext(FormContext);
  const { activeModal } = useContext(ModalContext);
  const edit = activeModal === "edit";
  const [rolesData, setRolesData] = useState<MergedColumnType[]>([]);
  const [pagesData, setPagesData] = useState<MergedColumnType[]>([]);

  const rolesOptions = rolesData.map((item) => ({
    value: item.id,
    label: item.name,
  }));

  const pagesOptions = pagesData.map((item) => ({
    value: item.id,
    label: item.name,
  }));

  const filterOption = (
    input: string,
    option?: { label: string; value: number }
  ) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const fetchRolesData = async () => {
    const content = (
      await request(
        `${location.pathname.split("/").slice(0, 2).join("/")}/getroles`,
        {
          method: "get",
          cancelTokenKey: "getroles",
        }
      )
    )?.content;
    setRolesData(content || []);
  };

  const fetchPagesData = async () => {
    const content = (
      await request(
        `${location.pathname.split("/").slice(0, 2).join("/")}/getpages`,
        {
          method: "get",
          cancelTokenKey: "getpages",
        }
      )
    )?.content;
    setPagesData(content || []);
  };

  return (
    <>
      <Form.Item
        label={intl("role")}
        name="role"
        rules={[
          {
            required: true,
            message: intl("role-required"),
          },
        ]}
      >
        <Select
          onChange={(value) => {
            form.setFieldsValue({ role: value });
          }}
          onDropdownVisibleChange={fetchRolesData}
          options={rolesOptions}
          allowClear
        />
      </Form.Item>
      <Form.Item
        label={intl("pages")}
        name="pages"
        rules={[
          {
            required: true,
            message: intl("pages-required"),
          },
        ]}
      >
        <Select
          mode={edit ? undefined : "multiple"}
          onChange={(_, value) => {
            form.setFieldsValue({
              pages: Array.isArray(value)
                ? value.map((v: any) => v.value)
                : [value],
            });
          }}
          onDropdownVisibleChange={fetchPagesData}
          filterOption={filterOption}
          options={pagesOptions}
          allowClear
        />
      </Form.Item>
      <Form.Item name="access" valuePropName="checked">
        <Checkbox>{intl("allow")}</Checkbox>
      </Form.Item>
    </>
  );
}

export function ButtonAccessSettings({ pathname }: { pathname: string }) {
  const location = useLocation();
  const { intl } = useContext(TranslationContext);
  const { form } = useContext(FormContext);
  const { activeModal } = useContext(ModalContext);
  const edit = activeModal === "edit";
  const [rolesData, setRolesData] = useState<MergedColumnType[]>([]);
  const [pagesData, setPagesData] = useState<MergedColumnType[]>([]);
  const [buttonsData, setButtonsData] = useState<MergedColumnType[]>([]);

  const rolesOptions = rolesData.map((item) => ({
    value: item.id,
    label: item.name,
  }));

  const pagesOptions = pagesData.map((item) => ({
    value: item.id,
    label: item.name,
  }));

  const buttonsOptions = buttonsData.map((item) => ({
    value: item.id,
    label: item.name,
  }));

  const fetchRolesData = async () => {
    const content = (
      await request(
        `${location.pathname.split("/").slice(0, 2).join("/")}/getroles`,
        {
          method: "get",
          cancelTokenKey: "getroles",
        }
      )
    )?.content;
    setRolesData(content || []);
  };

  const fetchPagesData = async () => {
    const content = (
      await request(
        `${location.pathname.split("/").slice(0, 2).join("/")}/getpages`,
        {
          method: "get",
          cancelTokenKey: "getpages",
        }
      )
    )?.content;
    setPagesData(content || []);
  };

  const fetchButtonsData = async () => {
    const content = (
      await request(
        `${location.pathname.split("/").slice(0, 2).join("/")}/getbuttons`,
        {
          method: "get",
          cancelTokenKey: "getbuttons",
        }
      )
    )?.content;
    setButtonsData(content || []);
  };

  return (
    <>
      <Form.Item
        label={intl("role")}
        name="role"
        rules={[
          {
            required: true,
            message: intl("role-required"),
          },
        ]}
      >
        <Select
          onChange={(value) => {
            form.setFieldsValue({ role: value });
          }}
          onDropdownVisibleChange={fetchRolesData}
          options={rolesOptions}
          allowClear
        />
      </Form.Item>
      <Form.Item
        label={intl("pages")}
        name="pages"
        rules={[
          {
            required: true,
            message: intl("pages-required"),
          },
        ]}
      >
        <Select
          mode={edit ? undefined : "multiple"}
          onChange={(_, value) => {
            form.setFieldsValue({
              pages: Array.isArray(value)
                ? value.map((v: any) => v.value)
                : [value],
            });
          }}
          onDropdownVisibleChange={fetchPagesData}
          options={pagesOptions}
          allowClear
        />
      </Form.Item>
      <Form.Item
        label={intl("buttons")}
        name="buttons"
        rules={[
          {
            required: true,
            message: intl("buttons-required"),
          },
        ]}
      >
        <Select
          mode={edit ? undefined : "multiple"}
          onChange={(_, value) => {
            form.setFieldsValue({
              buttons: Array.isArray(value)
                ? value.map((v: any) => v.value)
                : [value],
            });
          }}
          onDropdownVisibleChange={fetchButtonsData}
          options={buttonsOptions}
          allowClear
        />
      </Form.Item>
      <Form.Item name="access" valuePropName="checked">
        <Checkbox>{intl("allow")}</Checkbox>
      </Form.Item>
    </>
  );
}

export function ServerSettings({ pathname }: { pathname: string }) {
  const { intl } = useContext(TranslationContext);

  return (
    <>
      <Form.Item
        label={intl("server-name")}
        name="serverName"
        rules={[
          {
            required: true,
            message: intl("server-name-required"),
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label={intl("server-code")}
        name="serverCode"
        rules={[
          {
            required: true,
            message: intl("server-code-required"),
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label={intl("imap-host")}
        name="imapHost"
        rules={[
          {
            required: true,
            message: intl("imap-host-required"),
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label={intl("imap-port")}
        name="imapPort"
        rules={[
          {
            required: true,
            message: intl("imap-port-required"),
          },
        ]}
      >
        <Input />
      </Form.Item>
    </>
  );
}

export function Users({ pathname }: { pathname: string }) {
  const location = useLocation();
  const { intl } = useContext(TranslationContext);
  const { activeModal } = useContext(ModalContext);
  const { form } = useContext(FormContext);
  const { rowData } = useContext(TableContext);
  const { sessionData } = useContext(SessionContext);
  const [rolesData, setRolesData] = useState<MergedColumnType[]>([]);
  const edit = activeModal === "edit";

  function setFormFields() {
    if (/* edit &&  */ rowData) {
      const fieldsValue: any = {};
      if (rowData["/directories/employees"]) {
        fieldsValue.fullName = rowData["/directories/employees"].id;
        fieldsValue.structuralUnit = `${
          rowData["/directories/employees"].data.name_otdel ?? ""
        }\\${rowData["/directories/employees"].data.posada ?? ""}`;
      }
      form.setFieldsValue(fieldsValue);
    }
  }

  const onGeneratePassword = () => {
    const newPassword = generatePassword();
    form.setFieldsValue({ password: newPassword });
  };

  const rolesOptions = rolesData.map((item) => ({
    value: item.id,
    label: item.name,
  }));

  const fetchRolesData = async () => {
    const content = (
      await request(
        `${location.pathname.split("/").slice(0, 2).join("/")}/getroles`,
        {
          method: "get",
          cancelTokenKey: "getroles",
        }
      )
    )?.content;
    setRolesData(content || []);
  };

  return (
    <>
      <Title level={4}>{intl("personal-info")}</Title>
      <Form.Item label={intl("full-name-pib")} name="fullName">
        <Drawer
          url="/directories/employees"
          name={edit ? sessionData.FIO : undefined}
          name2={rowData["/directories/employees"]?.data.fio}
          setFormFields={setFormFields}
          fieldName={"fullName"}
        >
          <Directories url="/directories/employees" />
        </Drawer>
      </Form.Item>
      <Form.Item label={intl("structural-unit-position")} name="structuralUnit">
        <Input disabled={true} />
      </Form.Item>
      <Form.Item
        label={intl("sed-user")}
        name="login"
        rules={[
          {
            required: true,
            message: intl("sed-user-required"),
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label={intl("login-email")}
        name="email"
        rules={[
          {
            required: true,
            message: intl("login-email-required"),
          },
          {
            pattern:
              /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            message: intl("email-pattern"),
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Space className={styles["space"]}>
        <Form.Item
          label={intl("password")}
          name="password"
          rules={[
            {
              // required: !props.data && true,
              message: intl("password-required"),
            },
            {
              pattern:
                /(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z!@#$%^&*_]{8,}/g,
              message: intl("input-correct-password"),
            },
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item label=" ">
          <Tooltip title={intl("generate-password")}>
            <Button onClick={onGeneratePassword}>
              <KeyOutlined />
            </Button>
          </Tooltip>
        </Form.Item>
      </Space>
      <Form.Item
        label={intl("role")}
        name="role"
        rules={[
          {
            required: true,
            message: intl("role-required"),
          },
        ]}
      >
        <Select
          labelInValue
          onChange={(value) => {
            form.setFieldsValue({ role: value });
          }}
          onDropdownVisibleChange={fetchRolesData}
          options={rolesOptions}
          allowClear
        />
      </Form.Item>
      <Title level={4}>{intl("signs")}</Title>
      <Space size={"large"}>
        <Form.Item name="webVersion" valuePropName="checked">
          <Checkbox disabled={true}>{intl("web-version")}</Checkbox>
        </Form.Item>
        <Form.Item name="allowAccess" valuePropName="checked">
          <Checkbox>{intl("allow-access")}</Checkbox>
        </Form.Item>
        <Form.Item
          name="allowAccessHidden"
          valuePropName="checked"
          hidden={true}
        >
          <Checkbox />
        </Form.Item>
      </Space>
    </>
  );
}
