import { useContext } from "react";
import styles from "./styles.module.scss";

import { ModalProps } from "./types";
import { TranslationContext } from "../../../../contexts/translation/index.context";
//UI
import Modal from "antd/lib/modal";
import Button from "antd/lib/button";

export default function ModalComponent(props: ModalProps) {
  const { name, isOpen, onOk, onCancel, footerButtons, children } = props;
  const { intl } = useContext(TranslationContext);
  const style =
    name === "isModalOpen" ? styles["modal-with-footer"] : styles["modal"];
  const showOkButton = name !== "isScan";

  return (
    <Modal
      className={style}
      open={isOpen}
      okText={intl("ok")}
      onOk={onOk}
      onCancel={onCancel}
      destroyOnClose
      footer={
        showOkButton ? (
          footerButtons
        ) : (
          <Button key="cancel" onClick={onCancel}>
            {intl("cancel")}
          </Button>
        )
      }
    >
      {children}
    </Modal>
  );
}
