// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_main-layout__dTijG {
  min-height: 100vh;
  font-size: 16px;
}

.styles_sider__fAoBa {
  flex: 0 0 300px !important;
  max-width: 300px !important;
  min-width: 300px !important;
  width: 300px !important;
  background: #07496b !important;
  padding: 0 1rem 3rem;
}

.styles_sider-content__mrJz1 {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
}

.styles_sider-link__CjS4I {
  text-decoration: underline;
  color: #ffffff;
}

.styles_header__IIkE\\+ {
  background: transparent;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.styles_dropdown__Mdt5Z {
  color: #07496b;
}
.styles_dropdown__Mdt5Z :where(.css-dev-only-do-not-override-1vtf12y)[class^=ant-space],
.styles_dropdown__Mdt5Z :where(.css-dev-only-do-not-override-1vtf12y)[class*=" ant-space"] {
  font-size: 16px;
}

.styles_content__eaRM4 {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/hoc/auth-layout/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;EACA,eAAA;AACF;;AAEA;EACE,0BAAA;EACA,2BAAA;EACA,2BAAA;EACA,uBAAA;EACA,8BAAA;EACA,oBAAA;AACF;;AAEA;EACE,aAAA;EACA,sBAAA;EACA,yBAAA;EACA,YAAA;AACF;;AAEA;EACE,0BAAA;EACA,cAAA;AACF;;AAEA;EACE,uBAAA;EACA,aAAA;EACA,yBAAA;EACA,mBAAA;AACF;;AAEA;EACE,cAAA;AACF;AACI;;EAEE,eAAA;AACN;;AAIA;EACE,aAAA;EACA,sBAAA;EACA,6BAAA;EACA,mBAAA;AADF","sourcesContent":[".main-layout {\r\n  min-height: 100vh;\r\n  font-size: 16px;\r\n}\r\n\r\n.sider {\r\n  flex: 0 0 300px !important;\r\n  max-width: 300px !important;\r\n  min-width: 300px !important;\r\n  width: 300px !important;\r\n  background: #07496b !important;\r\n  padding: 0 1rem 3rem;\r\n}\r\n\r\n.sider-content {\r\n  display: flex;\r\n  flex-direction: column;\r\n  justify-content: flex-end;\r\n  height: 100%;\r\n}\r\n\r\n.sider-link {\r\n  text-decoration: underline;\r\n  color: #ffffff;\r\n}\r\n\r\n.header {\r\n  background: transparent;\r\n  display: flex;\r\n  justify-content: flex-end;\r\n  align-items: center;\r\n}\r\n\r\n.dropdown {\r\n  color: #07496b;\r\n  :global {\r\n    :where(.css-dev-only-do-not-override-1vtf12y)[class^=\"ant-space\"],\r\n    :where(.css-dev-only-do-not-override-1vtf12y)[class*=\" ant-space\"] {\r\n      font-size: 16px;\r\n    }\r\n  }\r\n}\r\n\r\n.content {\r\n  display: flex;\r\n  flex-direction: column;\r\n  justify-content: space-around;\r\n  align-items: center;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main-layout": `styles_main-layout__dTijG`,
	"sider": `styles_sider__fAoBa`,
	"sider-content": `styles_sider-content__mrJz1`,
	"sider-link": `styles_sider-link__CjS4I`,
	"header": `styles_header__IIkE+`,
	"dropdown": `styles_dropdown__Mdt5Z`,
	"content": `styles_content__eaRM4`
};
export default ___CSS_LOADER_EXPORT___;
