import styles from "../../../styles.module.scss";

import { createColumn } from '../utils';
import { MergedColumnType } from "../../types";
//UI
import type { ColumnType } from "antd/es/table";
import Checkbox from "antd/lib/checkbox";
//Icons
import PaperClipOutlined from "@ant-design/icons/PaperClipOutlined";
import MessageOutlined from "@ant-design/icons/MessageOutlined";
import LinkOutlined from "@ant-design/icons/LinkOutlined";

export const incomingColumns = (
  intl: (id: string) => string,
  getColumnSearchProps?: (dataIndex: keyof MergedColumnType) => ColumnType<MergedColumnType>,
  filters?: { text: string; value: boolean }[]
) => [
  createColumn(<span title={intl("availability-of-attachments")}><PaperClipOutlined /></span>, "fFile", 70, getColumnSearchProps, (a, b) => a.fFile - b.fFile, (text, record) => record.fFile === 1 ? <PaperClipOutlined /> : null),
  // createColumn("K", "fControl", 150, getColumnSearchProps, (a, b) => a.fControl - b.fControl),
  {
    title: (
        <span title={intl("availability-of-control-terms")}>K</span>
    ),
    dataIndex: "fControl",
    key: "fControl",
    align: "center" as const,
    width: 70,
    ellipsis: true,
    render: (_: any, record: MergedColumnType) => (
      <Checkbox
        className={styles["checkbox"]}
        checked={!!record.fControl}
        disabled
      />
    ),
    filters,
    onFilter: (value: any, record: MergedColumnType) => !!record.fControl === value,
  },
  createColumn(<span title={intl("availability-of-discussions")}><MessageOutlined /></span>, "fMessage", 70, getColumnSearchProps, (a, b) => a.fMessage - b.fMessage, (text, record) => record.fMessage === 1 ? <MessageOutlined /> : null),
  createColumn(<span title={intl("availability-of-related-documents")}><LinkOutlined /></span>, "fRelation", 70, getColumnSearchProps, (a, b) => a.fRelation - b.fRelation, (text, record) => record.fRelation === 1 ? <LinkOutlined /> : null),
  createColumn(intl("document-number"), "DocNum", 150, getColumnSearchProps, (a, b) => a.DocNum?.localeCompare(b.DocNum)),
  createColumn(intl("document-date"), "DocDate", 170, getColumnSearchProps, (a, b) => a.DocDate?.localeCompare(b.DocDate)),
  {
    title: intl("project"),
    dataIndex: "fProject",
    key: "fProject",
    align: "center" as const,
    width: 100,
    ellipsis: true,
    render: (_: any, record: MergedColumnType) => (
      <Checkbox
        className={styles["checkbox"]}
        checked={!!record.fProject}
        disabled
      />
    ),
    filters,
    onFilter: (value: any, record: MergedColumnType) => !!record.fProject === value,
  },
  createColumn(intl("type-of-document"), "typeDocName", 350, getColumnSearchProps, (a, b) => a.typeDocName?.localeCompare(b.typeDocName)),
  createColumn(intl("summary"), "Content", 400, getColumnSearchProps, (a, b) => a.Content?.localeCompare(b.Content)),
  createColumn(intl("current-stage"), "ActionCurName", 400, getColumnSearchProps, (a, b) => a.ActionCurName?.localeCompare(b.ActionCurName)),
  createColumn(intl("place-of-registration"), "PlaseRegistration", 350, getColumnSearchProps, (a, b) => a.PlaseRegistration?.localeCompare(b.PlaseRegistration)),
  // createColumn(intl("co-executors"), "CoExecutors", undefined, getColumnSearchProps, (a, b) => a.CoExecutors?.localeCompare(b.CoExecutors)),
  // createColumn(intl("date-of-control"), "DateControl", 170, getColumnSearchProps, (a, b) => a.DateControl?.localeCompare(b.DateControl)),
  // createColumn(intl("recipient"), "Recipient", undefined, getColumnSearchProps, (a, b) => a.Recipient?.localeCompare(b.Recipient)),
  // createColumn(intl("resolution"), "Resolution", undefined, getColumnSearchProps, (a, b) => a.Resolution?.localeCompare(b.Resolution)),
  // {
  //   title: intl("current-stage"),
  //   dataIndex: "actions",
  //   key: "actions",
  // },
];

export const resolutionsColumns = (
  intl: (id: string) => string,
  getColumnSearchProps?: (dataIndex: keyof MergedColumnType) => ColumnType<MergedColumnType>,
  filters?: { text: string; value: boolean }[]
) => [
  {
    title: intl("c"),
    dataIndex: "DocControl",
    key: "DocControl",
    align: "center" as const,
    ellipsis: true,
    render: (_: any, record: MergedColumnType) => (
      <Checkbox
        className={styles["checkbox"]}
        checked={!!record.DocControl}
        disabled
      />
    ),
    filters,
    onFilter: (value: any, record: MergedColumnType) => !!record.DocControl === value,
  },
  createColumn(<PaperClipOutlined />, "DocFileCount", undefined, getColumnSearchProps, (a, b) => a.DocFileCount - b.DocFileCount),
  createColumn(intl("registration-number"), "DocNum", undefined, getColumnSearchProps, (a, b) => a.DocNum?.localeCompare(b.DocNum)),
  createColumn(intl("registration-date"), "DocDate", undefined, getColumnSearchProps, (a, b) => a.DocDate?.localeCompare(b.DocDate), (date: string) => new Date(date).toLocaleDateString("uk-UA")),
  createColumn(intl("type-of-document"), "DocType", undefined, getColumnSearchProps, (a, b) => a.DocType?.localeCompare(b.DocType)),
  createColumn(intl("summary"), "DocContent", undefined, getColumnSearchProps, (a, b) => a.DocContent?.localeCompare(b.DocContent)),
  createColumn(intl("correspondent"), "DocCoresp", undefined, getColumnSearchProps, (a, b) => a.DocCoresp?.localeCompare(b.DocCoresp)),
  createColumn(intl("higher-level-resolution"), "ParentActionText", undefined, getColumnSearchProps, (a, b) => a.ParentActionText?.localeCompare(b.ParentActionText)),
  createColumn(intl("author-of-the-resolution"), "ActionPerson", undefined, getColumnSearchProps, (a, b) => a.ActionPerson?.localeCompare(b.ActionPerson)),
  createColumn(intl("resolution-date"), "ActionDate", undefined, getColumnSearchProps, (a, b) => a.ActionDate?.localeCompare(b.ActionDate)),
  createColumn(intl("resolution"), "ActionText", undefined, getColumnSearchProps, (a, b) => a.ActionText?.localeCompare(b.ActionText)),
  {
    // title: intl("current-stage"),
    dataIndex: "actions",
    key: "actions",
    align: "center" as const,
    render: () => <Checkbox />,
  },
];
