import styles from "../styles.module.scss";
import { useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";

import { TranslationContext } from "../../../../../../../contexts/translation/index.context";
import { FormContext } from "../../../../../../../contexts/form/index.context";
import { ModalContext } from "../../../../../../../contexts/modal/index.context";
import { TableContext } from "../../../../../../../contexts/table/index.context";
import { SessionContext } from "../../../../../../../contexts/session/index.context";
//Components
import Drawer from "../../../../../../ui/drawer";
import Directories from "../../../../../../directories";
//UI
import Form from "antd/lib/form";
import Input from "antd/lib/input";
import Typography from "antd/lib/typography";
import Space from "antd/lib/space";
import Select from "antd/lib/select";
import Checkbox from "antd/lib/checkbox";
import DatePicker from "antd/lib/date-picker";
//Locale
import "dayjs/locale/uk";

dayjs.extend(customParseFormat);
dayjs.locale("uk");

const { Title } = Typography;

type FormItemConfig = {
  [key: string]: { label: string; name: string }[];
};

const formItemConfig: FormItemConfig = {
  "/directories/profession-classifier": [
    { label: "kp", name: "kp" },
    { label: "okp", name: "okp" },
    { label: "etks", name: "etks" },
  ],
  "/directories/job-categories": [{ label: "code", name: "code" }],
  "/directories/types-of-structural-unit": [
    { label: "code", name: "code" },
    { label: "notes", name: "notes" },
  ],
};

export function HrDepartment({ pathname }: { pathname: string }) {
  const { intl } = useContext(TranslationContext);
  const config = formItemConfig[pathname];

  return (
    <>
      <Form.Item
        label={intl("name")}
        name="name"
        rules={[
          {
            required: true,
            message: intl("name-required"),
          },
        ]}
      >
        <Input />
      </Form.Item>
      {config &&
        config.map((item) => (
          <Form.Item
            label={intl(item.label)}
            name={item.name}
            rules={[
              {
                max: item.name === "okp" ? 5 : 10,
                message: intl("the-number-of-allowed"),
              },
            ]}
          >
            <Input />
          </Form.Item>
        ))}
    </>
  );
}

export function StructuralUnits({ pathname }: { pathname: string }) {
  const { intl } = useContext(TranslationContext);
  const { form } = useContext(FormContext);
  const { activeModal } = useContext(ModalContext);
  const { sessionData } = useContext(SessionContext);
  const { rowData } = useContext(TableContext);
  const edit = activeModal === "edit";

  function setFormFields() {
    if (/* edit &&  */ rowData) {
      const fieldsValue: any = {};
      if (rowData["/directories/types-of-structural-unit"]) {
        fieldsValue.subdivision =
          rowData["/directories/types-of-structural-unit"].id;
      }
      if (rowData["/directories/structural-units/refersTo"]) {
        fieldsValue.refersTo =
          rowData["/directories/structural-units/refersTo"].id;
      }
      if (rowData["/directories/structural-units/reorganized"]) {
        fieldsValue.reorganized =
          rowData["/directories/structural-units/reorganized"].id;
      }
      form.setFieldsValue(fieldsValue);
    }
  }

  return (
    <>
      <Form.Item label={intl("code")} name="code" rules={[{ max: 5 }]}>
        <Input />
      </Form.Item>
      <Form.Item label={intl("type2")} name="subdivision">
        <Drawer
          url="/directories/types-of-structural-unit"
          name={edit ? sessionData.name_subdivision : undefined}
          name2={rowData["/directories/types-of-structural-unit"]?.data.Name}
          setFormFields={setFormFields}
          fieldName={"subdivision"}
        >
          <Directories url="/directories/types-of-structural-unit" />
        </Drawer>
      </Form.Item>
      <Form.Item
        label={intl("full-name")}
        name="department"
        rules={[
          {
            required: true,
            message: intl("full-name-required"),
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item label={intl("short-name")} name="shortName">
        <Input />
      </Form.Item>
      <Form.Item label={intl("refers-to")} name="refersTo">
        <Drawer
          url="/directories/structural-units"
          name={edit ? sessionData.NameParent : undefined}
          name2={rowData["/directories/structural-units/refersTo"]?.data.Name}
          setFormFields={setFormFields}
          fieldName={"refersTo"}
        >
          <Directories url="/directories/structural-units" name="refersTo" />
        </Drawer>
      </Form.Item>
      <Form.Item label={intl("reorganized-into")} name="reorganized">
        <Drawer
          url="/directories/structural-units"
          name={edit ? sessionData.NameReorg : undefined}
          name2={
            rowData["/directories/structural-units/reorganized"]?.data.Name
          }
          setFormFields={setFormFields}
          fieldName={"reorganized"}
        >
          <Directories url="/directories/structural-units" name="reorganized" />
        </Drawer>
      </Form.Item>
      <Form.Item label={intl("notes")} name="notes">
        <Input />
      </Form.Item>
      <Form.Item name="isLiquidated" valuePropName="checked">
        <Checkbox>{intl("liquidated")}</Checkbox>
      </Form.Item>
    </>
  );
}

export function Positions({ pathname }: { pathname: string }) {
  const { intl } = useContext(TranslationContext);
  const { form } = useContext(FormContext);
  const { activeModal } = useContext(ModalContext);
  const { sessionData } = useContext(SessionContext);
  const { rowData } = useContext(TableContext);
  const edit = activeModal === "edit";

  function setFormFields() {
    if (/* edit &&  */ rowData) {
      const fieldsValue: any = {};
      if (rowData["/directories/job-categories"]) {
        fieldsValue.category = rowData["/directories/job-categories"].id;
      }
      if (rowData["/directories/profession-classifier"]) {
        fieldsValue.nameProf = rowData["/directories/profession-classifier"].id;
      }
      form.setFieldsValue(fieldsValue);
    }
  }
  return (
    <>
      <Form.Item label={intl("category")} name="category">
        <Drawer
          url="/directories/job-categories"
          name={edit ? sessionData.KATEGORIA : undefined}
          name2={rowData["/directories/job-categories"]?.data.Name}
          setFormFields={setFormFields}
          fieldName={"reorganized"}
        >
          <Directories url="/directories/job-categories" />
        </Drawer>
      </Form.Item>
      <Form.Item label={intl("code")} name="code" rules={[{ max: 20 }]}>
        <Input />
      </Form.Item>
      <Form.Item label={intl("profession-classifier")} name="nameProf">
        <Drawer
          url="/directories/profession-classifier"
          name={edit ? sessionData.NameProf : undefined}
          name2={rowData["/directories/profession-classifier"]?.data.Name}
          setFormFields={setFormFields}
          fieldName={"reorganized"}
        >
          <Directories url="/directories/profession-classifier" />
        </Drawer>
      </Form.Item>
      <Form.Item
        label={intl("position")}
        name="position"
        rules={[
          {
            required: true,
            message: intl("position-required"),
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item label={intl("description")} name="description">
        <Input />
      </Form.Item>
    </>
  );
}

export function EmployeesAndContactPersons({ pathname }: { pathname: string }) {
  const location = useLocation();
  const { intl } = useContext(TranslationContext);
  const { form } = useContext(FormContext);
  const { activeModal } = useContext(ModalContext);
  const { rowData } = useContext(TableContext);
  const { sessionData } = useContext(SessionContext);
  const edit = activeModal === "edit";
  const dateFormatList = ["DD.MM.YYYY", "DD-MM-YYYY"];

  const isContactPersonOrNonClerkEdit =
    activeModal === "contact-person" ||
    (activeModal === "edit" && !sessionData.is_clerk);

  const formItemData = isContactPersonOrNonClerkEdit
    ? {
        label: "enterprise-organization",
        name: "enterpriseOrganization",
        url: "/directories/correspondents",
      }
    : {
        label: "structural-unit",
        name: "structuralUnit",
        url: "/directories/structural-units",
      };

  function setFormFields() {
    if (/* edit &&  */ rowData) {
      const fieldsValue: any = {};
      if (rowData["/directories/correspondents"]) {
        fieldsValue.enterpriseOrganization =
          rowData["/directories/correspondents"].id;
      }
      if (rowData["/directories/structural-units"]) {
        fieldsValue.structuralUnit =
          rowData["/directories/structural-units"].id;
      }
      if (rowData["/directories/positions"]) {
        fieldsValue.position = rowData["/directories/positions"].id;
      }
      form.setFieldsValue(fieldsValue);
    }
  }

  function isLeapYear(year: number) {
    return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
  }

  const getDaysInMonth = (month: number, year: number) => {
    if (month === 2) {
      return isLeapYear(year) ? 29 : 28;
    } else if ([4, 6, 9, 11].includes(month)) {
      return 30;
    } else {
      return 31;
    }
  };

  const handleMonthChange = (value: string) => {
    form.setFieldsValue({ monthBirth: value });
    const month = parseInt(value);
    const year = form.getFieldValue("yearBirth");
    if (year) {
      const maxDay = getDaysInMonth(month, year);
      const day = form.getFieldValue("dayBirth");
      day &&
        form.setFieldsValue({
          dayBirth: Math.min(maxDay, form.getFieldValue("dayBirth")),
        });
    }
  };

  const handleYearChange = (event: any) => {
    const year = parseInt(event.target.value);
    const month = parseInt(form.getFieldValue("monthBirth"));
    if (month) {
      const maxDay = getDaysInMonth(month, year);
      const day = form.getFieldValue("dayBirth");
      day &&
        form.setFieldsValue({
          dayBirth: Math.min(maxDay, form.getFieldValue("dayBirth")),
        });
    }
  };

  console.log("ы: ", activeModal === "edit" && !sessionData.is_clerk);
  return (
    <>
      <Form.Item label={intl(formItemData.label)} name={formItemData.name}>
        <Drawer
          url={formItemData.url}
          name={
            edit
              ? !sessionData.is_clerk
                ? sessionData.sta_kontragent_name
                : sessionData.name_otdel
              : undefined
          }
          name2={
            isContactPersonOrNonClerkEdit
              ? rowData["/directories/correspondents"]?.data.Name
              : rowData["/directories/structural-units"]?.data.Name
          }
          setFormFields={setFormFields}
          fieldName={formItemData.name}
        >
          <Directories url={formItemData.url} />
        </Drawer>
      </Form.Item>
      {isContactPersonOrNonClerkEdit && (
        <Form.Item label={intl("structural-unit")} name="structuralUnit2">
          <Input />
        </Form.Item>
      )}
      <Form.Item label={intl("position")} name="position">
        <Drawer
          url="/directories/positions"
          name={edit ? sessionData.posada : undefined}
          name2={rowData["/directories/positions"]?.data.Name}
          setFormFields={setFormFields}
          fieldName={"position"}
        >
          <Directories url="/directories/positions" />
        </Drawer>
      </Form.Item>
      <Title level={4}>{intl("personal-data")}</Title>
      <Form.Item
        label={intl("last-name")}
        name="lastName"
        rules={[
          {
            required: true,
            message: intl("last-name-required"),
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label={intl("first-name")}
        name="firstName"
        rules={[
          {
            required: true,
            message: intl("first-name-required"),
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item label={intl("middle-name")} name="middleName">
        <Input />
      </Form.Item>
      {isContactPersonOrNonClerkEdit ? (
        <Space className={styles["space"]} size={"large"}>
          <Form.Item label={intl("date-birth")} name="birthday">
            <DatePicker format={dateFormatList} />
          </Form.Item>
          <Form.Item label={intl("for-envelopes")} name="forEnvelopes">
            <Input />
          </Form.Item>
        </Space>
      ) : (
        <>
          <Space className={styles["space"]} size={"large"}>
            <Form.Item
              label={intl("year-birth")}
              name="yearBirth"
              rules={[
                // { max: 4 },
                () => ({
                  validator(_, value) {
                    if (
                      !value ||
                      (value >= 1900 && value <= new Date().getFullYear())
                    ) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(intl("year-of-birth-was-entered-incorrectly"))
                    );
                  },
                }),
              ]}
            >
              <Input onChange={handleYearChange} />
            </Form.Item>
            <Form.Item label={intl("month-birth")} name="monthBirth">
              <Select
                // defaultValue={intl("january")}
                onChange={handleMonthChange}
                options={[
                  { value: "1", label: intl("january") },
                  { value: "2", label: intl("february") },
                  { value: "3", label: intl("march") },
                  { value: "4", label: intl("april") },
                  { value: "5", label: intl("may") },
                  { value: "6", label: intl("june") },
                  { value: "7", label: intl("july") },
                  { value: "8", label: intl("august") },
                  { value: "9", label: intl("september") },
                  { value: "10", label: intl("october") },
                  { value: "11", label: intl("november") },
                  { value: "12", label: intl("december") },
                ]}
              />
            </Form.Item>
            <Form.Item
              label={intl("day-birth")}
              name="dayBirth"
              rules={[
                () => ({
                  validator(_, value) {
                    // if (!value || (value >= 1 && value <= 31)) {
                    //   return Promise.resolve();
                    // }
                    // return Promise.reject(
                    //   new Error(intl("number-must-be-between"))
                    // );
                    const month = parseInt(form.getFieldValue("monthBirth"));
                    const year = parseInt(form.getFieldValue("yearBirth"));
                    if (!value || !month || !year) {
                      return Promise.resolve();
                    }
                    const maxDay = getDaysInMonth(month, year);
                    if (value >= 1 && value <= maxDay) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(`${intl("number-must-be-between")}${maxDay}`)
                    );
                  },
                }),
              ]}
            >
              <Input />
            </Form.Item>
          </Space>
          <Form.Item label={intl("for-envelopes")} name="forEnvelopes">
            <Input />
          </Form.Item>
        </>
      )}
      <Title level={4}>{intl("contact-information")}</Title>
      <Space className={styles["space"]} size={"large"}>
        <Form.Item label={intl("telephone")} name="telephone">
          <Input />
        </Form.Item>
        <Form.Item label={intl("fax")} name="fax">
          <Input />
        </Form.Item>
      </Space>
      <Space className={styles["space"]} size={"large"}>
        <Form.Item label={intl("mobile-phone")} name="mobilePhone">
          <Input />
        </Form.Item>
        <Form.Item label={intl("email")} name="email">
          <Input />
        </Form.Item>
      </Space>
      <Form.Item label={intl("no-cabinet")} name="noCabinet">
        <Input
          disabled={
            isContactPersonOrNonClerkEdit /* ||
            props.data?.type_rec === 1 */ && true
          }
        />
      </Form.Item>
      <Title level={4}>{intl("signs")}</Title>
      <Space size={"large"}>
        <Form.Item name="head" valuePropName="checked">
          <Checkbox>{intl("head")}</Checkbox>
        </Form.Item>
        <Form.Item name="rightControl" valuePropName="checked">
          <Checkbox
            disabled={
              isContactPersonOrNonClerkEdit /* || props.data?.type_rec === 1 */ &&
              true
            }
          >
            {intl("right-control")}
          </Checkbox>
        </Form.Item>
        <Form.Item name="released" valuePropName="checked">
          <Checkbox>{intl("released")}</Checkbox>
        </Form.Item>
      </Space>
    </>
  );
}
