import styles from "./styles.module.scss";
import { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { DocumentsFormProps, FilesType } from "../types";
import { request } from "../../../../services";
import { TranslationContext } from "../../../../contexts/translation/index.context";
import { SessionContext } from "../../../../contexts/session/index.context";
import { ModalContext } from "../../../../contexts/modal/index.context";
//Components
import FileEditor from "../../../ui/file-editor";
import DocumentsForm from "../add";
import Modal from "../../../ui/modal";
//UI
import Space from "antd/lib/space";
import Typography from "antd/lib/typography";
import Checkbox from "antd/lib/checkbox";
import Col from "antd/lib/col";
import Tabs from "antd/lib/tabs";
import type { TabsProps } from "antd/es/tabs";
//Icons
import FilePdfOutlined from "@ant-design/icons/FilePdfOutlined";

const { Title, Text } = Typography;

export default function EditDocumentsForm(props: DocumentsFormProps) {
  const location = useLocation();
  const { intl } = useContext(TranslationContext);
  const { sessionData } = useContext(SessionContext);
  const { refreshFileEditor, setRefreshFileEditor } = useContext(ModalContext);
  const [files, setFiles] = useState<FilesType[]>([]);
  const formComponent = <DocumentsForm />;

  useEffect(() => {
    fetchFiles();
  }, []);

  useEffect(() => {
    if (refreshFileEditor) {
      fetchFiles();
      setRefreshFileEditor(false);
    }
  }, [refreshFileEditor]);

  const fetchFiles = async () => {
    const content = (
      await request(
        `${location.pathname.split("/").slice(0, 2).join("/")}/getfiles`,
        {
          method: "post",
          body: { id: sessionData.bta_documents_id },
          cancelTokenKey: "getfiles",
        }
      )
    )?.content;
    setFiles(content || []);
  };

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: intl("passing"),
      children: "Content of Tab Pane 1",
    },
    {
      key: "2",
      label: intl("related"),
      children: "Content of Tab Pane 2",
    },
    {
      key: "3",
      label: intl("discussion"),
      children: "Content of Tab Pane 3",
    },
  ];

  return (
    <Space className={styles["space"]} align="start">
      <FileEditor files={files} />
      <div>
        <Modal name="edit">{formComponent}</Modal>
        <Title className={styles["title"]} level={4}>{`${intl(
          "type-of-document"
        )} № ${sessionData.DocNum} ${intl("from")} ${
          sessionData.DocDate
        }`}</Title>
        <Checkbox defaultChecked={sessionData.fProject}>
          {intl("project")}
        </Checkbox>
        <div className={styles["text"]}>
          <Col span={4}>
            <Title level={5}>{intl("content")}:</Title>
          </Col>
          <Text>{sessionData.Content}</Text>
        </div>
        <div className={styles["text"]}>
          <Col span={4}>
            <Title level={5}>{intl("author")}:</Title>
          </Col>
          <Text>{sessionData.Author}</Text>
        </div>
        <div className={styles["text"]}>
          <Col span={4}>
            <Title level={5}>{intl("the-signatory")}:</Title>
          </Col>
          <Text>{sessionData.SignFIO}</Text>
        </div>
        <Tabs defaultActiveKey="1" items={items} />
      </div>
    </Space>
  );
}
