import styles from "./styles.module.scss";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { LineProps } from "./types";
import { request } from "../../../../services";
//UI
import Line from "@ant-design/plots/lib/components/line";

export default function LineComponent(props: LineProps) {
  const [data, setData] = useState([]);
  const location = useLocation();

  // useEffect(() => {
  //   fetchData();
  // }, []);

  // const fetchData = async () => {
  //   const content = (
  //     await request(`${location.pathname}/getinfodocenter`, {
  //       method: "get",
  //       cancelTokenKey: "getinfodocenter",
  //     })
  //   )?.content;
  //   setData(content || []);
  // };

  const dataLine = props.data.map((item: any) => {
    let date = new Date(item.date_x);
    return { ...item, date_x: date.toLocaleDateString("uk-UA") };
  });

  const config = {
    data: dataLine,
    xField: "date_x",
    yField: "x",
    label: {},
    forceFit: true,
    title: {
      visible: true,
      text: "Название графика",
    },
    point: {
      size: 5,
      shape: "diamond",
      style: {
        fill: "white",
        stroke: "#5B8FF9",
        lineWidth: 2,
      },
    },
    tooltip: {
      showMarkers: false,
    },
    state: {
      active: {
        style: {
          shadowBlur: 4,
          stroke: "#000",
          fill: "red",
        },
      },
    },
    interactions: [
      {
        type: "marker-active",
      },
    ],
  };

  return <Line className={styles["chart"]} {...config} />;
}
