import { createContext, useEffect, useState } from "react";
import { TranslationContextValue, TranslationProviderProps } from "./types";
import { translate } from "../../translation";

export const TranslationContext = createContext<TranslationContextValue>({
  intl: (selector: string) => selector,
  setIntl: () => {},
});

export function TranslationProvider({
  children,
  lang,
}: TranslationProviderProps) {
  const [intl, setIntl] = useState<(selector: string) => string>(
    () => (selector: string) => selector
  );

  useEffect(() => {
    setIntl(() => (selector: string) => translate(selector, lang ?? "uk"));
  }, [lang]);

  return (
    <TranslationContext.Provider value={{ intl, setIntl }}>
      {children}
    </TranslationContext.Provider>
  );
}
