// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_space__0C2CJ {
  padding: 1rem;
  align-items: flex-start;
  gap: 80px !important;
}

.styles_title__YSVTK {
  color: #004c5d !important;
  margin-top: 20px;
}

.styles_chart__2xGZs {
  width: 500px;
  height: 250px !important;
}

.styles_submit-a-resolution__WK3\\+P,
.styles_sign__FlkjM,
.styles_other-tasks__-Efdt {
  border: 2px solid #a6aeb1;
  width: 250px;
  height: 150px;
}

.styles_submit-a-resolution__WK3\\+P {
  background-color: rgb(241, 255, 199);
}

.styles_sign__FlkjM {
  background-color: rgb(167, 209, 254);
}

.styles_other-tasks__-Efdt {
  background-color: rgb(218, 234, 234);
}

.styles_statistic__wQ7hY {
  text-align: center;
  font-weight: 700;
}
.styles_statistic__wQ7hY .ant-statistic-title {
  color: #004c5d;
  font-size: 18px;
}
.styles_statistic__wQ7hY .ant-statistic-content {
  color: #004c5d;
  font-size: 45px;
}`, "",{"version":3,"sources":["webpack://./src/pages/information/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,uBAAA;EACA,oBAAA;AACF;;AAEA;EACE,yBAAA;EACA,gBAAA;AACF;;AAEA;EACE,YAAA;EACA,wBAAA;AACF;;AAEA;;;EAGE,yBAAA;EACA,YAAA;EACA,aAAA;AACF;;AAEA;EACE,oCAAA;AACF;;AAEA;EACE,oCAAA;AACF;;AAEA;EACE,oCAAA;AACF;;AAEA;EACE,kBAAA;EACA,gBAAA;AACF;AACI;EACE,cAAA;EACA,eAAA;AACN;AACI;EACE,cAAA;EACA,eAAA;AACN","sourcesContent":[".space {\r\n  padding: 1rem;\r\n  align-items: flex-start;\r\n  gap: 80px !important;\r\n}\r\n\r\n.title {\r\n  color: #004c5d !important;\r\n  margin-top: 20px;\r\n}\r\n\r\n.chart {\r\n  width: 500px;\r\n  height: 250px !important;\r\n}\r\n\r\n.submit-a-resolution,\r\n.sign,\r\n.other-tasks {\r\n  border: 2px solid #a6aeb1;\r\n  width: 250px;\r\n  height: 150px;\r\n}\r\n\r\n.submit-a-resolution {\r\n  background-color: rgb(241, 255, 199);\r\n}\r\n\r\n.sign {\r\n  background-color: rgb(167, 209, 254);\r\n}\r\n\r\n.other-tasks {\r\n  background-color: rgb(218, 234, 234);\r\n}\r\n\r\n.statistic {\r\n  text-align: center;\r\n  font-weight: 700;\r\n  :global {\r\n    .ant-statistic-title {\r\n      color: #004c5d;\r\n      font-size: 18px;\r\n    }\r\n    .ant-statistic-content {\r\n      color: #004c5d;\r\n      font-size: 45px;\r\n    }\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"space": `styles_space__0C2CJ`,
	"title": `styles_title__YSVTK`,
	"chart": `styles_chart__2xGZs`,
	"submit-a-resolution": `styles_submit-a-resolution__WK3+P`,
	"sign": `styles_sign__FlkjM`,
	"other-tasks": `styles_other-tasks__-Efdt`,
	"statistic": `styles_statistic__wQ7hY`
};
export default ___CSS_LOADER_EXPORT___;
