import styles from "./styles.module.scss";

import { DocumentsProps } from "./types";
import { ModalProvider } from "../../contexts/modal/index.context";
import { SessionProvider } from "../../contexts/session/index.context";
import {
  TableProvider,
  TableContext,
} from "../../contexts/table/index.context";
//Components
import DatePickers from "../ui/datapickers";
import DocumentsButtons from "../ui/documents-buttons";
import Table from "../ui/table";

export default function Documents(props: DocumentsProps) {
  return (
    <ModalProvider>
      {/* <TableProvider> */}
      <SessionProvider url={props.url}>
        <DocumentsButtons />
        <DatePickers />
        <Table />
      </SessionProvider>
      {/* </TableProvider> */}
    </ModalProvider>
  );
}
