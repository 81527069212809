import styles from "./styles.module.scss";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";

import MainLayout from "../../hoc/main-layout";
import * as Actions from "../../store/actions";
import { translate } from "../../translation";
import { PropsTypes } from "../types";
import { StateTypes } from "../../store/types";
import { request } from "../../services";
//UI
import Space from "antd/lib/space";
import Typography from "antd/lib/typography";
import Card from "antd/lib/card";
import Statistic from "antd/lib/statistic";
//Charts
import Line from "../../components/ui/charts/line";
import Column from "../../components/ui/charts/column";
import Pie from "../../components/ui/charts/pie";

const { Title } = Typography;

function Information(props: PropsTypes) {
  const [infoDocEnter, setInfoDocEnter] = useState([]);
  const [infoCivilTreatment, setInfoCivilTreatment] = useState([]);
  const [infoDocAllYear, setInfoDocAllYear] = useState([]);
  const [infoResolution, setInfoResolution] = useState(undefined);
  const [infoSign, setInfoSign] = useState(undefined);
  const [infoAction, setInfoAction] = useState(undefined);
  const location = useLocation();

  const intl = (selector: string) => {
    return translate(selector, props.lang ?? "uk");
  };

  useEffect(() => {
    // fetchInfoDocEnter();
    // fetchInfoCivilTreatment();
    // fetchInfoDocAllYear();
    // fetchInfoResolution();
    // fetchInfoSign();
    // fetchInfoInfoAction();
    fetchInfoAll();
  }, []);

  // const fetchInfoDocEnter = async () => {
  //   const content = (
  //     await request(`${location.pathname}/getinfodocenter`, {
  //       method: "get",
  //       cancelTokenKey: "getinfodocenter",
  //     })
  //   )?.content;
  //   setInfoDocEnter(content || []);
  // };

  // const fetchInfoCivilTreatment = async () => {
  //   const content = (
  //     await request(`${location.pathname}/getinfociviltreatment`, {
  //       method: "get",
  //       cancelTokenKey: "getinfodocenter",
  //     })
  //   )?.content;
  //   setInfoCivilTreatment(content || []);
  // };

  // const fetchInfoDocAllYear = async () => {
  //   const content = (
  //     await request(`${location.pathname}/getinfodocallyear`, {
  //       method: "get",
  //       cancelTokenKey: "getinfodocallyear",
  //     })
  //   )?.content;
  //   setInfoDocAllYear(content || []);
  // };

  // const fetchInfoResolution = async () => {
  //   const content = (
  //     await request(`${location.pathname}/getinforesolution`, {
  //       method: "get",
  //       cancelTokenKey: "getinforesolution",
  //     })
  //   )?.content;
  //   setInfoResolution(content?.[0].x || []);
  // };

  // const fetchInfoSign = async () => {
  //   const content = (
  //     await request(`${location.pathname}/getinfosign`, {
  //       method: "get",
  //       cancelTokenKey: "getinfosign",
  //     })
  //   )?.content;
  //   setInfoSign(content?.[0].x || []);
  // };

  // const fetchInfoInfoAction = async () => {
  //   const content = (
  //     await request(`${location.pathname}/getinfoaction`, {
  //       method: "get",
  //       cancelTokenKey: "getinfoaction",
  //     })
  //   )?.content;
  //   console.log("content: ", content);
  //   setInfoAction(content?.[0].x || []);
  // };

  const fetchInfoAll = async () => {
    const content = (
      await request(`${location.pathname}/getinfoall`, {
        method: "get",
        cancelTokenKey: "getinfoall",
      })
    )?.content;
    setInfoDocEnter(content?.filter((item: any) => item.r === 1) || []);
    setInfoCivilTreatment(content?.filter((item: any) => item.r === 2) || []);
    setInfoDocAllYear(content?.filter((item: any) => item.r === 3) || []);
    setInfoResolution(content?.filter((item: any) => item.r === 4)?.[0].x || []);
    setInfoSign(content?.filter((item: any) => item.r === 5)?.[0].x || []);
    setInfoAction(content?.filter((item: any) => item.r === 6)?.[0].x || []);
  };

  return (
    <MainLayout activeLink={window.location.pathname}>
      <Space className={styles["space"]}>
        <Space direction="vertical">
          <Title level={4} className={styles["title"]}>
            {intl("incoming-documents-last-2-weeks")}
          </Title>
          <Line data={infoDocEnter} />
          <Title level={4} className={styles["title"]}>
            {intl("citizen-appeals-last-2-weeks")}
          </Title>
          <Column data={infoCivilTreatment} />
          <Title level={4} className={styles["title"]}>
            {intl("working-with-documents-for-the-current-year")}
          </Title>
          <Pie data={infoDocAllYear} />
        </Space>
        <Space direction="vertical">
          <Title level={4} className={styles["title"]}>
            {intl("own-tasks")}
          </Title>
          <Space>
            <Card className={styles["submit-a-resolution"]} bordered={false}>
              <Statistic
                className={styles["statistic"]}
                title={intl("submit-a-resolution")}
                value={infoResolution}
                groupSeparator=""
              />
            </Card>
            <Card className={styles["sign"]} bordered={false}>
              <Statistic
                className={styles["statistic"]}
                title={intl("sign")}
                value={infoSign}
                groupSeparator=""
              />
            </Card>
          </Space>
          <Card className={styles["other-tasks"]} bordered={false}>
            <Statistic
              className={styles["statistic"]}
              title={intl("other-tasks")}
              value={infoAction}
              groupSeparator=""
            />
          </Card>
        </Space>
      </Space>
    </MainLayout>
  );
}

function mapStateToProps(state: StateTypes) {
  return {
    lang: state.lang?.value,
    message: state.authLayout?.value,
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    changeLanguage: (language: string) =>
      dispatch({ type: Actions.CHANGE_LANGUAGE, language }),
    raiseMessage: (message: string) =>
      dispatch({ type: Actions.RAISE_MESSAGE, message }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Information);
