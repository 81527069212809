import { useContext } from "react";

import { TranslationContext } from "../../../../../../../contexts/translation/index.context";
import { FormContext } from "../../../../../../../contexts/form/index.context";
import { ModalContext } from "../../../../../../../contexts/modal/index.context";
import { TableContext } from "../../../../../../../contexts/table/index.context";
import { SessionContext } from "../../../../../../../contexts/session/index.context";
//Components
import Drawer from "../../../../../../ui/drawer";
import Directories from "../../../../../../directories";
//UI
import Form from "antd/lib/form";
import Space from "antd/lib/space";
import Input from "antd/lib/input";
import Checkbox from "antd/lib/checkbox";
import InputNumber from "antd/lib/input-number";
import Typography from "antd/lib/typography";
import DatePicker from "antd/lib/date-picker";
import Table from "antd/lib/table";
import type { CheckboxProps } from "antd";

const { Title } = Typography;
const { TextArea } = Input;

interface DocumentCharacteristicsProps {
  pathname: string;
  onChange: CheckboxProps["onChange"];
}

export function NomenclatureOfCasesCurrent({ pathname }: { pathname: string }) {
  const { intl } = useContext(TranslationContext);
  const { form } = useContext(FormContext);
  const { activeModal } = useContext(ModalContext);
  const { sessionData } = useContext(SessionContext);
  const { rowData } = useContext(TableContext);
  const edit = activeModal === "edit";
  const dateFormatList = ["DD.MM.YYYY", "DD-MM-YYYY"];

  function setFormFields() {
    if (/* edit &&  */ rowData) {
      const fieldsValue: any = {};
      if (rowData["/directories/structural-units"]) {
        fieldsValue.structuralUnit =
          rowData["/directories/structural-units"].id;
      }
      form.setFieldsValue(fieldsValue);
    }
  }

  return (
    <>
      <Form.Item label={intl("structural-unit")} name="structuralUnit">
        <Drawer
          url="/directories/structural-units"
          name={edit ? sessionData.NAME_OTDEL : undefined}
          name2={rowData["/directories/structural-units"]?.data.Name}
          setFormFields={setFormFields}
          fieldName={"structuralUnit"}
        >
          <Directories url="/directories/structural-units" />
        </Drawer>
      </Form.Item>
      <Space size={"large"}>
        <Form.Item
          label={intl("nomenclature-no")}
          name="numNomenclature"
          rules={[
            { max: 20 },
            {
              required: true,
              message: intl("nomenclature-no-required"),
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={intl("year")}
          name="year"
          rules={[
            { type: "number", min: 1900, max: new Date().getFullYear() },
            {
              required: true,
              message: intl("year-required"),
            },
          ]}
        >
          <InputNumber />
        </Form.Item>
      </Space>
      <Title level={4}>{intl("approved")}</Title>
      <Space size={"large"}>
        <Form.Item label={intl("from")} name="from">
          <DatePicker format={dateFormatList} />
        </Form.Item>
        <Form.Item name="valid" valuePropName="checked">
          <Checkbox>{intl("valid")}</Checkbox>
        </Form.Item>
      </Space>
      <Form.Item label={intl("document")} name="document">
        <Input />
      </Form.Item>
      <Form.Item label={intl("notes")} name="approvalNotes">
        <TextArea rows={4} />
      </Form.Item>
      <Form.Item label={intl("general-notes")} name="notes">
        <TextArea rows={4} />
      </Form.Item>
      <Form.Item name="addNum" valuePropName="checked">
        <Checkbox>{intl("add-num-nomenclature")}</Checkbox>
      </Form.Item>
      <Form.Item name="addC" valuePropName="checked">
        <Checkbox>{intl("add-letter-c")}</Checkbox>
      </Form.Item>
    </>
  );
}

export function NomenclatureOfCases({ pathname }: { pathname: string }) {
  const { intl } = useContext(TranslationContext);
  const { form } = useContext(FormContext);
  const { activeModal } = useContext(ModalContext);
  const { sessionData } = useContext(SessionContext);
  const { rowData } = useContext(TableContext);
  const edit = activeModal === "edit";

  function setFormFields() {
    if (/* edit &&  */ rowData) {
      const fieldsValue: any = {};
      if (rowData["/directories/nomenclature-of-cases-current"]) {
        fieldsValue.nomenclatureOfCasesCurrent =
          rowData["/directories/nomenclature-of-cases-current"].id;
      }
      form.setFieldsValue(fieldsValue);
    }
  }

  return (
    <>
      <Form.Item
        label={intl("nomenclature-of-cases-current")}
        name="nomenclatureOfCasesCurrent"
      >
        <Drawer
          url="/directories/nomenclature-of-cases-current"
          name={edit ? sessionData.NAME_OTDEL : undefined}
          name2={
            rowData["/directories/nomenclature-of-cases-current"]?.data
              .NAME_OTDEL
          }
          setFormFields={setFormFields}
          fieldName={"nomenclatureOfCasesCurrent"}
        >
          <Directories url="/directories/nomenclature-of-cases-current" />
        </Drawer>
      </Form.Item>
      <Form.Item
        label={intl("index")}
        name="index"
        rules={[
          { max: 20 },
          {
            required: true,
            message: intl("index-required"),
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label={intl("heading")}
        name="heading"
        rules={[
          {
            required: true,
            message: intl("heading-required"),
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item label={intl("shelf-life")} name="shelfLife">
        <Input />
      </Form.Item>
      <Form.Item label={intl("notes")} name="notes">
        <Input />
      </Form.Item>
    </>
  );
}

export function ResolutionTemplates({ pathname }: { pathname: string }) {
  const { intl } = useContext(TranslationContext);
  const { form } = useContext(FormContext);
  const { activeModal } = useContext(ModalContext);
  const { sessionData } = useContext(SessionContext);
  const { rowData, contextTableData } = useContext(TableContext);
  const edit = activeModal === "edit";

  function setFormFields() {
    if (/* edit &&  */ rowData) {
      const fieldsValue: any = {};
      if (rowData["/directories/employees"]) {
        fieldsValue.author = rowData["/directories/employees"].id;
      }
      form.setFieldsValue(fieldsValue);
    }
  }

  return (
    <>
      <Form.Item label={intl("text-resolution")} name="text">
        <Input />
      </Form.Item>
      <Form.Item label={intl("author")} name="author">
        <Drawer
          url="/directories/employees"
          name={edit ? sessionData.fio : undefined}
          name2={rowData["/directories/employees"]?.data.fio}
          setFormFields={setFormFields}
          fieldName={"structuralUnit"}
        >
          <Directories url="/directories/employees" />
        </Drawer>
      </Form.Item>
      <Form.Item name="notDisplayListPerformers" valuePropName="checked">
        <Checkbox>{intl("not-display-the-list-of-performers")}</Checkbox>
      </Form.Item>
      <Form.Item /* label={intl("author")} */ name="executors">
        <Directories
          url="/directories/resolution-templates/executors"
          id={sessionData.id}
          activeModal={activeModal}
          form={form}
          tableData={contextTableData}
        />
        {/* <Table dataSource={dataSource} columns={columns} rowSelection={rowSelection} /> */}
      </Form.Item>
    </>
  );
}

export function DocumentCharacteristics({
  pathname,
  onChange,
}: DocumentCharacteristicsProps) {
  const { intl } = useContext(TranslationContext);
  return (
    <>
      <Form.Item
        label={intl("code")}
        name="code"
        rules={[{ max: 10, message: intl("the-number-of-allowed") }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label={intl("name")}
        name="name"
        rules={[
          {
            required: true,
            message: intl("name-required"),
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item label={intl("notes")} name="notes">
        <Input />
      </Form.Item>
      {pathname === "/directories/type-of-document" && (
        <Space>
          <Form.Item name="incoming" valuePropName="checked">
            <Checkbox value="1" onChange={onChange}>
              {intl("incoming")}
            </Checkbox>
          </Form.Item>
          <Form.Item name="outcoming" valuePropName="checked">
            <Checkbox value="2" onChange={onChange}>
              {intl("outcoming")}
            </Checkbox>
          </Form.Item>
          <Form.Item name="internal" valuePropName="checked">
            <Checkbox value="3" onChange={onChange}>
              {intl("internal")}
            </Checkbox>
          </Form.Item>
        </Space>
      )}
    </>
  );
}
