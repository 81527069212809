import { setDocumentChar } from "./directories/document-characteristics";
import { setHrDepartment } from "./directories/hr-department";
import { setGeneral } from "./directories/general";
import { setAdministration } from "./administration";

export const setFieldValuesFunctions = {
  ...setDocumentChar,
  ...setHrDepartment,
  ...setGeneral,
  ...setAdministration,
};
