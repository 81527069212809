import styles from "./styles.module.scss";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { PieProps } from "./types";
import { request } from "../../../../services";
//UI
import Pie from "@ant-design/plots/lib/components/pie";

export default function PieComponent(props: PieProps) {
  const [data, setData] = useState([]);
  const location = useLocation();

  // useEffect(() => {
  //   fetchData();
  // }, []);

  // const fetchData = async () => {
  //   const content = (
  //     await request(`${location.pathname}/getinfodocallyear`, {
  //       method: "get",
  //       cancelTokenKey: "getinfodocallyear",
  //     })
  //   )?.content;
  //   setData(content || []);
  // };

  const config = {
    appendPadding: 10,
    data: props.data,
    angleField: "x",
    colorField: "name_x",
    radius: 0.9,
    // label: {
    //   type: "inner",
    //   offset: "-30%",
    //   content: ({ percent }: { percent: number }) =>
    //     `${(percent * 100).toFixed(0)}%`,
    //   style: {
    //     fontSize: 14,
    //     textAlign: "center",
    //   },
    // },
    interactions: [
      {
        type: "element-active",
      },
    ],
  };

  return <Pie className={styles["chart"]} {...config} />;
}
