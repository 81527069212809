// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_popconfirm__GgMkb {
  background-color: #5abab9;
}

.styles_popconfirm__GgMkb:not(:disabled):hover {
  background-color: #5abab9 !important;
  color: #3f434e !important;
}

.styles_button__AoKnR {
  background-color: transparent;
  border-color: transparent;
  box-shadow: none;
  color: #6e7583;
}

.styles_button__AoKnR:not(:disabled):not(.styles_ant-btn-disabled__ZNtUz):hover {
  background-color: transparent;
  border-color: transparent;
  box-shadow: none;
  color: #282d36;
}

.styles_button__AoKnR:disabled, .styles_button__AoKnR.styles_ant-btn-disabled__ZNtUz {
  background-color: transparent;
  border-color: transparent;
}

.styles_custom-button__CJpzd {
  background-color: #5abab9;
}

.styles_custom-button__CJpzd:not(:disabled):hover {
  background-color: #5abab9 !important;
  color: #3f434e !important;
}`, "",{"version":3,"sources":["webpack://./src/components/ui/popconfirm-files/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,yBAAA;AACF;;AAEA;EACE,oCAAA;EACA,yBAAA;AACF;;AAEA;EACE,6BAAA;EACA,yBAAA;EACA,gBAAA;EACA,cAAA;AACF;;AAGA;EACE,6BAAA;EACA,yBAAA;EACA,gBAAA;EACA,cAAA;AAAF;;AAGA;EACE,6BAAA;EACA,yBAAA;AAAF;;AAGA;EACE,yBAAA;AAAF;;AAGA;EACE,oCAAA;EACA,yBAAA;AAAF","sourcesContent":[".popconfirm {\r\n  background-color: #5abab9;\r\n}\r\n\r\n.popconfirm:not(:disabled):hover {\r\n  background-color: #5abab9 !important;\r\n  color: #3f434e !important;\r\n}\r\n\r\n.button {\r\n  background-color: transparent;\r\n  border-color: transparent;\r\n  box-shadow: none;\r\n  color: #6e7583;\r\n  // width: 100%;\r\n}\r\n\r\n.button:not(:disabled):not(.ant-btn-disabled):hover {\r\n  background-color: transparent;\r\n  border-color: transparent;\r\n  box-shadow: none;\r\n  color: #282d36;\r\n}\r\n\r\n.button:disabled, .button.ant-btn-disabled {\r\n  background-color: transparent;\r\n  border-color: transparent;\r\n}\r\n\r\n.custom-button {\r\n  background-color: #5abab9;\r\n}\r\n\r\n.custom-button:not(:disabled):hover {\r\n  background-color: #5abab9 !important;\r\n  color: #3f434e !important;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"popconfirm": `styles_popconfirm__GgMkb`,
	"button": `styles_button__AoKnR`,
	"ant-btn-disabled": `styles_ant-btn-disabled__ZNtUz`,
	"custom-button": `styles_custom-button__CJpzd`
};
export default ___CSS_LOADER_EXPORT___;
