// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_profile-space__5wvOc {
  padding: 0.5rem 1rem;
}

.styles_forms-space__Re5I- {
  column-gap: 50px;
}

.styles_form__jwEDm .ant-form-item {
  width: 300px;
}

.styles_new-password__-JOZk {
  width: 245px !important;
}

.styles_button__BSHgc {
  background-color: #5abab9;
  color: #ffffff;
}

.styles_button__BSHgc:hover,
.styles_button__BSHgc:focus {
  color: #3f434e !important;
  border-color: inherit !important;
}`, "",{"version":3,"sources":["webpack://./src/pages/profile/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,oBAAA;AACF;;AAEA;EACE,gBAAA;AACF;;AAII;EACE,YAAA;AADN;;AAMA;EACE,uBAAA;AAHF;;AAMA;EACE,yBAAA;EACA,cAAA;AAHF;;AASA;;EAEE,yBAAA;EACA,gCAAA;AANF","sourcesContent":[".profile-space {\r\n  padding: 0.5rem 1rem;\r\n}\r\n\r\n.forms-space {\r\n  column-gap: 50px;\r\n}\r\n\r\n.form {\r\n  :global {\r\n    .ant-form-item {\r\n      width: 300px;\r\n    }\r\n  }\r\n}\r\n\r\n.new-password {\r\n  width: 245px !important;\r\n}\r\n\r\n.button {\r\n  background-color: #5abab9;\r\n  color: #ffffff;\r\n  // height: auto;\r\n  // padding: 5px 50px;\r\n  // font-size: 16px;\r\n}\r\n\r\n.button:hover,\r\n.button:focus {\r\n  color: #3f434e !important;\r\n  border-color: inherit !important;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"profile-space": `styles_profile-space__5wvOc`,
	"forms-space": `styles_forms-space__Re5I-`,
	"form": `styles_form__jwEDm`,
	"new-password": `styles_new-password__-JOZk`,
	"button": `styles_button__BSHgc`
};
export default ___CSS_LOADER_EXPORT___;
