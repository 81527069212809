import { useContext, useRef, useState } from "react";
import { useLocation } from "react-router-dom";

import { TranslationContext } from "../../../../contexts/translation/index.context";
import { MergedColumnType } from "./types";
//Columns
import { incomingColumns, resolutionsColumns } from "./columns/documents";
import {
  nomenclatureOfCasesCurrentColumns,
  nomenclatureOfCasesColumns,
  resolutionTemplatesColumns,
  executorsColumns,
  documentCharColumns,
} from "./columns/directories/document-characteristics";
import {
  professionClassifierColumns,
  jobCategoriesColumns,
  typesOfStructuralUnitColumns,
  employeesColumns,
  structuralUnitsColumns,
  positionsColumns,
  employeesAndContactPersonsColumns,
} from "./columns/directories/hr-department";
import { correspondentsColumns } from "./columns/directories/general";
import {
  configuringPageAccessColumns,
  buttonAccessSettingsColumns,
  serverSettingsColumns,
  usersColumns,
} from "./columns/administration";
//UI
import type { InputRef } from "antd/es/input";
import type { ColumnsType, ColumnType } from "antd/es/table";
import type { FilterConfirmProps } from "antd/es/table/interface";
import Button from "antd/lib/button";
import Input from "antd/lib/input";
import Space from "antd/lib/space";
import Tooltip from "antd/lib/tooltip";
import Highlighter from "react-highlight-words";
//Icons
import SearchOutlined from "@ant-design/icons/SearchOutlined";
import { TableContext } from "../../../../contexts/table/index.context";

export function useTableColumns(
  reset: boolean,
  setReset: React.Dispatch<React.SetStateAction<boolean>>,
  url: string | undefined,
  tableData: MergedColumnType[]
) {
  type DataIndex = keyof MergedColumnType;
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef<InputRef>(null);
  const { intl } = useContext(TranslationContext);
  const { visibleColumn } = useContext(TableContext);
  const location = useLocation();

  const filters = [
    { text: intl("marked"), value: true },
    { text: intl("unmarked"), value: false },
  ];

  const handleSearch = (
    selectedKeys: string[],
    confirm: (param?: FilterConfirmProps) => void,
    dataIndex: DataIndex
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (
    clearFilters: () => void,
    confirm: (param?: FilterConfirmProps) => void
  ) => {
    clearFilters();
    confirm({ closeDropdown: false });
    setSearchText("");
    setSearchedColumn("");
  };

  const handleResetAll = () => {
    setSearchText("");
    setSearchedColumn("");
    setReset(true);
    reset && setReset(false);
  };

  const getColumnSearchProps = (
    dataIndex: DataIndex
  ): ColumnType<MergedColumnType> => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`${intl("search")}...`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            handleSearch(selectedKeys as string[], confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() =>
              handleSearch(selectedKeys as string[], confirm, dataIndex)
            }
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            {intl("search")}
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters, confirm)}
            size="small"
            style={{ width: 90 }}
          >
            {intl("reset")}
          </Button>
          <Button size="small" onClick={handleResetAll}>
            {intl("reset-all")}
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              setSelectedKeys(["пусті"]);
              handleSearch(["empty"], confirm, dataIndex);
            }}
          >
            {intl("search-empty")}
          </Button>
          {/* <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText((selectedKeys as string[])[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            {intl("filter")}
          </Button> */}
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            {intl("close")}
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) => {
      return value === "пусті"
        ? record[dataIndex] === null || record[dataIndex] === ""
        : record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes((value as string).toLowerCase())
        : false;
    },
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Tooltip title={text}>
          <Highlighter
            highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text ? text.toString() : ""}
          />
        </Tooltip>
      ) : (
        text
      ),
  });

  type ColumnFunction = (
    intl: (id: string) => string,
    getColumnSearchProps?: (
      dataIndex: keyof MergedColumnType
    ) => ColumnType<MergedColumnType>,
    filters?: { text: string; value: boolean }[],
    data?: MergedColumnType[],
    visible?: boolean,
  ) => ColumnsType<MergedColumnType>;

  const columnFunctions: Record<string, ColumnFunction> = {
    "/documents/incoming": incomingColumns,
    "/documents/outcoming": incomingColumns,
    "/documents/internal": incomingColumns,
    "/documents/resolutions": resolutionsColumns,
    "/documents/for-signature": resolutionsColumns,
    "/directories/nomenclature-of-cases-current":
      nomenclatureOfCasesCurrentColumns,
    "/directories/nomenclature-of-cases": nomenclatureOfCasesColumns,
    "/directories/resolution-templates": resolutionTemplatesColumns,
    "/directories/resolution-templates/executors": executorsColumns,
    "/directories/type-of-document": documentCharColumns,
    "/directories/document-index": documentCharColumns,
    "/directories/type-of-delivery": documentCharColumns,
    "/directories/profession-classifier": professionClassifierColumns,
    "/directories/job-categories": jobCategoriesColumns,
    "/directories/types-of-structural-unit": typesOfStructuralUnitColumns,
    "/directories/employees": employeesColumns,
    "/directories/structural-units": structuralUnitsColumns,
    "/directories/positions": positionsColumns,
    "/directories/employees-and-contact-persons":
      employeesAndContactPersonsColumns,
    "/directories/correspondents": correspondentsColumns,
    "/administration/configuring-page-access": configuringPageAccessColumns,
    "/administration/button-access-settings": buttonAccessSettingsColumns,
    "/administration/server-settings": serverSettingsColumns,
    "/administration/users": usersColumns,
  };
  const getColumnFunction = columnFunctions[url || location.pathname];

  let columns: ColumnsType<MergedColumnType> = [];

  if (getColumnFunction) {
    columns = getColumnFunction(intl, getColumnSearchProps, filters, tableData, visibleColumn);
  }

  return columns;
}
