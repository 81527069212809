import { createContext, useEffect, useState } from "react";
import { TableState, TableContextValue, TableProviderProps } from "./types";
import { MergedColumnType } from "../../components/ui/table/utils/types";

export const TableContext = createContext<TableContextValue>({
  buttonStr: "",
  setButtonStr: () => {},
  checkboxStr: "",
  setCheckboxStr: () => {},
  url: "",
  setUrl: () => {},
  rowData: {},
  setRowData: () => {},
  numenclatureId: undefined,
  setNumenclatureId: () => {},
  contextTableData: [],
  setContextTableData: () => {},
  visibleColumn: false,
  setVisibleColumn: () => {},
  // record: {},
  // setRecord: () => {},
  // formIds: {},
  // setFormIds: () => {},
});

export function TableProvider({ children }: TableProviderProps) {
  // const [tableState, setTableState] = useState<TableState>({
  //   selectedRow: undefined,
  //   pageSize: 10,
  //   currentPage: 1,
  // });
  const [buttonStr, setButtonStr] = useState("");
  const [checkboxStr, setCheckboxStr] = useState("");
  const [url, setUrl] = useState("");
  const [rowData, setRowData] = useState({});
  const [numenclatureId, setNumenclatureId] = useState(undefined);
  const [contextTableData, setContextTableData] = useState<MergedColumnType[]>([]);
  const [visibleColumn, setVisibleColumn] = useState(false);
  // const [record, setRecord] = useState({});
  // const [formIds, setFormIds] = useState({});

  // useEffect(() => {
  //   const savedState = sessionStorage.getItem("table_state");
  //   if (savedState) {
  //     setTableState(JSON.parse(savedState));
  //   }
  // }, []);

  // useEffect(() => {
  //   sessionStorage.setItem("table_state", JSON.stringify(tableState));
  // }, [tableState]);

  return (
    <TableContext.Provider
      value={{
        /*  tableState,
        setTableState, */
        buttonStr,
        setButtonStr,
        checkboxStr,
        setCheckboxStr,
        url,
        setUrl,
        rowData,
        setRowData,
        numenclatureId,
        setNumenclatureId,
        contextTableData,
        setContextTableData,
        visibleColumn, 
        setVisibleColumn,
        // record,
        // setRecord,
        // formIds,
        // setFormIds,
      }}
    >
      {children}
    </TableContext.Provider>
  );
}
