import styles from "./styles.module.scss";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, useLocation } from "react-router-dom";

import AuthLayout from "../../../hoc/auth-layout";
import * as Actions from "../../../store/actions";
import { translate } from "../../../translation";
import { PropsTypes, InjectedPropsTypes } from "../../types";
import { StateTypes } from "../../../store/types";
import { request } from "../../../services";
import User from "../../../services/user";
import { encryptData } from "../../../services/encryption";
//UI
import Form from "antd/lib/form";
import Input from "antd/lib/input";
import Button from "antd/lib/button";
import message from "antd/lib/message";

function Login(props: PropsTypes) {
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const { raiseMessage } = props as InjectedPropsTypes;

  useEffect(() => {
    if (User.token) {
      const currentUser = User.decode();
      if (!currentUser) {
        window.location.href = "/auth";
      } else {
        window.location.href = "/information";
      }
    }
  }, []);

  const intl = (selector: string) => {
    return translate(selector, props.lang ?? "uk");
  };

  const decode = async () => {
    const currentUser = User.decode();
    if (!currentUser) return null;

    const content = (
      await request(`${location.pathname}/accesses`, {
        method: "get",
        cancelTokenKey: "accesses",
      })
    )?.content;
    encryptData(content);
    /* if (currentUser.roleId === 3 && currentUser.WebDel) {
      props.history.replace("/charts/receipts");
    } else */ if (!currentUser.WebDel) {
      raiseMessage?.("message-invalid-del-access");
    } else {
      window.location.href = "/information";
    }
  };

  const info = () => {
    message.error(intl("data-entered-is-incorrect"));
  };

  const onFinish = async (values: any) => {
    console.log("Success:", values);
    setLoading(true);
    const { success, content } = await request(`${location.pathname}/login`, {
      method: "post",
      body: values,
      cancelTokenKey: "login",
    });
    console.log("success: ", success);
    setLoading(false);
    if (success) {
      User.store(content);
      decode();
    } else {
      raiseMessage?.("message-server-is-not-responding");
      info();
    }
  };

  const onFinishFailed = async (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <AuthLayout>
      <Form
        name="auth form"
        className={styles["form"]}
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item
          label={intl("login")}
          name="email"
          rules={[
            { required: true, message: intl("login-required") },
            {
              pattern:
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
              message: intl("email-pattern"),
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={intl("password")}
          name="password"
          rules={[{ required: true, message: intl("password-required") }]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item>
          <Button
            className={styles["button"]}
            type="primary"
            htmlType="submit"
            loading={loading}
          >
            {intl("sign-in")}
          </Button>
        </Form.Item>

        <Form.Item>
          <Link
            to="/auth/password-recovery"
            className={styles["forgot-password"]}
          >
            {intl("forgot-password")}
          </Link>
        </Form.Item>
      </Form>
    </AuthLayout>
  );
}

function mapStateToProps(state: StateTypes) {
  return {
    lang: state.lang?.value,
    message: state.authLayout?.value,
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    changeLanguage: (language: string) =>
      dispatch({ type: Actions.CHANGE_LANGUAGE, language }),
    closeMessage: () => dispatch({ type: Actions.CLOSE_MESSAGE }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
