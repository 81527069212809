// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_form__KfjiI .ant-form-item {
  margin-bottom: 8px;
}
.styles_form__KfjiI .ant-form-item .ant-form-item-label {
  padding: 0 0 2px;
}
.styles_form__KfjiI .ant-typography {
  margin-top: 10px;
}
.styles_form__KfjiI :where(.css-dev-only-do-not-override-2q8sxy).ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-inner,
.styles_form__KfjiI :where(.css-dev-only-do-not-override-2q8sxy).ant-checkbox:not(.ant-checkbox-disabled):hover .ant-checkbox-inner {
  border-color: #4b9294;
}
.styles_form__KfjiI :where(.css-dev-only-do-not-override-2q8sxy).ant-checkbox-checked .ant-checkbox-inner {
  background-color: #4b9294;
  border-color: #4b9294;
}
.styles_form__KfjiI :where(.css-dev-only-do-not-override-2q8sxy).ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-checked:not(.ant-checkbox-disabled) .ant-checkbox-inner {
  background-color: #4b9294;
  border-color: transparent;
}`, "",{"version":3,"sources":["webpack://./src/components/forms/documents/add/styles.module.scss"],"names":[],"mappings":"AAEI;EACE,kBAAA;AADN;AAII;EACE,gBAAA;AAFN;AAKI;EACE,gBAAA;AAHN;AAMI;;EAQE,qBAAA;AAVN;AAaI;EAEE,yBAAA;EACA,qBAAA;AAZN;AAeI;EAKE,yBAAA;EACA,yBAAA;AAjBN","sourcesContent":[".form {\r\n  :global {\r\n    .ant-form-item {\r\n      margin-bottom: 8px;\r\n    }\r\n\r\n    .ant-form-item .ant-form-item-label {\r\n      padding: 0 0 2px;\r\n    }\r\n\r\n    .ant-typography {\r\n      margin-top: 10px;\r\n    }\r\n\r\n    :where(.css-dev-only-do-not-override-2q8sxy).ant-checkbox-wrapper:not(\r\n        .ant-checkbox-wrapper-disabled\r\n      ):hover\r\n      .ant-checkbox-inner,\r\n    :where(.css-dev-only-do-not-override-2q8sxy).ant-checkbox:not(\r\n        .ant-checkbox-disabled\r\n      ):hover\r\n      .ant-checkbox-inner {\r\n      border-color: #4b9294;\r\n    }\r\n\r\n    :where(.css-dev-only-do-not-override-2q8sxy).ant-checkbox-checked\r\n      .ant-checkbox-inner {\r\n      background-color: #4b9294;\r\n      border-color: #4b9294;\r\n    }\r\n\r\n    :where(.css-dev-only-do-not-override-2q8sxy).ant-checkbox-wrapper:not(\r\n        .ant-checkbox-wrapper-disabled\r\n      ):hover\r\n      .ant-checkbox-checked:not(.ant-checkbox-disabled)\r\n      .ant-checkbox-inner {\r\n      background-color: #4b9294;\r\n      border-color: transparent;\r\n    }\r\n  }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": `styles_form__KfjiI`
};
export default ___CSS_LOADER_EXPORT___;
