import { CreateFolderProps } from "./types";
import { translateDictionary } from "../../../translation";
import { request } from "../../../services";

//UI
import Form from "antd/lib/form";
import Input from "antd/lib/input";
import message from "antd/lib/message";

export default function CreateFolder(props: CreateFolderProps) {
  const info = (text: string) => {
    message.info(text);
  };

  const onFinish = async (values: any) => {
    console.log("Success:", values);

    const str = translateDictionary(values.nameFolder);
    const content = (
      await request("/administration/getpages", {
        method: "get",
        cancelTokenKey: "getpages",
      })
    )?.content;
    const item = content.find((i: any) => i.route === `/cabinet/${str}`);
    if (item) {
      info(props.intl("folder-already-created"));
    } else {
      await request(`${props.url}/createfolder`, {
        method: "post",
        body: { ...values, str: str },
        cancelTokenKey: "createfolder",
      });
      info(props.intl("alert-changes"));
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <Form
      name="create-folder"
      form={props.form}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <Form.Item
        name="nameFolder"
        label={props.intl("name-folder")}
        rules={[
          {
            required: true,
            message: props.intl("name-folder-required"),
          },
        ]}
      >
        <Input />
      </Form.Item>
    </Form>
  );
}
