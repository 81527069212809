import styles from "./styles.module.scss";
import { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import dayjs, { Dayjs } from "dayjs";

import { request } from "../../../services";
import { DatePickersProps } from "./types";

import { DatepickerContext } from "../../../contexts/datepicker/index.context";
import { ModalContext } from "../../../contexts/modal/index.context";
import { TranslationContext } from "../../../contexts/translation/index.context";
import { SessionContext } from "../../../contexts/session/index.context";
//UI
import Space from "antd/lib/space";
import DatePicker from "antd/lib/date-picker";
import type { DatePickerProps } from "antd/es/date-picker";
import Checkbox from "antd/lib/checkbox";
import type { CheckboxChangeEvent } from "antd/es/checkbox";
//Locale
import "dayjs/locale/uk";

dayjs.locale("uk");

export default function DatePickersComponent(props: DatePickersProps) {
  const dateFormatList = ["DD.MM.YYYY", "DD-MM-YYYY"];
  const [from, setFrom] = useState<Dayjs>(dayjs().startOf("year"));
  const [to, setTo] = useState<Dayjs>(dayjs().endOf("year"));
  const [inWork, setInWork] = useState(false);
  const location = useLocation();
  const context = useContext(ModalContext);
  const datePickerContext = useContext(DatepickerContext);
  const { intl } = useContext(TranslationContext);
  const { setSessionData } = useContext(SessionContext);

  // useEffect(() => {
  //   fetchData(from, to);
  // }, []);

  useEffect(() => {
    if (onChangeFrom || context.refresh) {
      fetchData(from, to);
      context.setRefresh(false);
    }
  }, [context.refresh]);

  // const updateObject = (arr: any, obj: any) => {
  //   const foundObj = arr.find(item => item.id === obj.id);
  //   if (foundObj) {
  //     Object.assign(obj, foundObj);
  //   }
  // };

  const fetchData = async (from: Dayjs, to: Dayjs, inWork?: boolean) => {
    datePickerContext.setLoading(true);
    const content = (
      await request(`${location.pathname}/getdatatable`, {
        method: "post",
        body: {
          from,
          to,
          inWork,
        },
        cancelTokenKey: "getdatatable",
      })
    )?.content;
    datePickerContext.setTableData(content);
    datePickerContext.setLoading(false);
  };

  const onChangeFrom: DatePickerProps["onChange"] = (date, dateString) => {
    if (date) {
      setFrom(date);
      fetchData(date, to);
    }
  };

  const onChangeTo: DatePickerProps["onChange"] = (date, dateString) => {
    if (date) {
      setTo(date);
      fetchData(from, date);
    }
  };

  const onChangeInWork = async (e: CheckboxChangeEvent) => {
    if (e.target.checked) {
      setInWork(e.target.checked);
      fetchData(from, to, e.target.checked);
    }
  };

  return (
    <Space size={"middle"} className={styles["datepicker-space"]}>
      <DatePicker
        defaultValue={dayjs().startOf("year")}
        format={dateFormatList}
        onChange={onChangeFrom}
      />
      <DatePicker
        defaultValue={dayjs().endOf("year")}
        format={dateFormatList}
        onChange={onChangeTo}
      />
      <Checkbox onChange={onChangeInWork}>{intl("in-work")}</Checkbox>
    </Space>
  );
}
