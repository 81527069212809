// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_space__Qq3Xt {
  width: 100%;
  padding: 0.5rem 0.5rem 0;
  column-gap: 70px;
}
.styles_space__Qq3Xt .ant-space-item:last-child {
  width: 100%;
}

.styles_dropdown__e4SjY {
  background-color: #5abab9;
  color: #ffffff;
}

.styles_dropdown__e4SjY:not(:disabled):hover {
  background-color: #5abab9;
  color: #3f434e;
}

.styles_button__bdy4h {
  background-color: transparent;
  border-color: transparent;
  box-shadow: none;
  color: #6e7583;
}

.styles_button__bdy4h:not(:disabled):not(.styles_ant-btn-disabled__jni23):hover {
  background-color: transparent;
  border-color: transparent;
  box-shadow: none;
  color: #282d36;
}

.styles_button__bdy4h:disabled,
.styles_button__bdy4h.styles_ant-btn-disabled__jni23 {
  background-color: transparent;
  border-color: transparent;
}

.styles_button__bdy4h:focus-visible {
  outline: none;
}`, "",{"version":3,"sources":["webpack://./src/components/ui/documents-buttons/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,wBAAA;EACA,gBAAA;AACF;AACI;EACE,WAAA;AACN;;AAIA;EACE,yBAAA;EACA,cAAA;AADF;;AAIA;EACE,yBAAA;EACA,cAAA;AADF;;AAIA;EACE,6BAAA;EACA,yBAAA;EACA,gBAAA;EACA,cAAA;AADF;;AAKA;EACE,6BAAA;EACA,yBAAA;EACA,gBAAA;EACA,cAAA;AAFF;;AAKA;;EAEE,6BAAA;EACA,yBAAA;AAFF;;AAKA;EACE,aAAA;AAFF","sourcesContent":[".space {\r\n  width: 100%;\r\n  padding: 0.5rem 0.5rem 0;\r\n  column-gap: 70px;\r\n  :global {\r\n    .ant-space-item:last-child {\r\n      width: 100%;\r\n    }\r\n  }\r\n}\r\n\r\n.dropdown {\r\n  background-color: #5abab9;\r\n  color: #ffffff;\r\n}\r\n\r\n.dropdown:not(:disabled):hover {\r\n  background-color: #5abab9;\r\n  color: #3f434e;\r\n}\r\n\r\n.button {\r\n  background-color: transparent;\r\n  border-color: transparent;\r\n  box-shadow: none;\r\n  color: #6e7583;\r\n  // width: 100%;\r\n}\r\n\r\n.button:not(:disabled):not(.ant-btn-disabled):hover {\r\n  background-color: transparent;\r\n  border-color: transparent;\r\n  box-shadow: none;\r\n  color: #282d36;\r\n}\r\n\r\n.button:disabled,\r\n.button.ant-btn-disabled {\r\n  background-color: transparent;\r\n  border-color: transparent;\r\n}\r\n\r\n.button:focus-visible {\r\n  outline: none;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"space": `styles_space__Qq3Xt`,
	"dropdown": `styles_dropdown__e4SjY`,
	"button": `styles_button__bdy4h`,
	"ant-btn-disabled": `styles_ant-btn-disabled__jni23`
};
export default ___CSS_LOADER_EXPORT___;
