import { useState } from "react";

type ModalsState = Record<string, boolean>;

export const useModals = (modalNames: string[]) => {
  const [modalsState, setModalsState] = useState<ModalsState>(
    modalNames.reduce<ModalsState>(
      (acc, name) => ({ ...acc, [name]: false }),
      {}
    )
  );

  const showModal = (modalName: string) => {
    setModalsState((prevState) => ({ ...prevState, [modalName]: true }));
  };

  const hideModal = (modalName: string) => {
    setModalsState((prevState) => ({ ...prevState, [modalName]: false }));
  };

  return { modalsState, showModal, hideModal };
};
