import { useContext } from "react";
import { TranslationContext } from "../../../../../../../contexts/translation/index.context";

//UI
import Form from "antd/lib/form";
import Input from "antd/lib/input";
import Space from "antd/lib/space";
import Checkbox from "antd/lib/checkbox";

// type FormItemConfig = {
//   [key: string]: { label: string; name: string }[];
// };

// const formItemConfig: FormItemConfig = {
//   "/directories/profession-classifier": [
//     { label: "kp", name: "kp" },
//     { label: "okp", name: "okp" },
//     { label: "etks", name: "etks" },
//   ],
//   "/directories/job-categories": [{ label: "code", name: "code" }],
//   "/directories/types-of-structural-unit": [
//     { label: "code", name: "code" },
//     { label: "notes", name: "notes" },
//   ],
// };

export function General({ pathname }: { pathname: string }) {
  const { intl } = useContext(TranslationContext);
  // const config = formItemConfig[pathname];

  return (
    <>
      <Form.Item
        label={intl("name")}
        name="name"
        rules={[
          {
            required: true,
            message: intl("name-required"),
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item label={intl("code")} name="code">
        <Input />
      </Form.Item>
      <Form.Item label={intl("code-okpo")} name="codeOkpo">
        <Input />
      </Form.Item>
      <Form.Item label={intl("abbreviated")} name="abbreviated">
        <Input />
      </Form.Item>
      <Form.Item label={intl("for-envelope")} name="forEnvelope">
        <Input />
      </Form.Item>
      <Form.Item label={intl("email")} name="email">
        <Input />
      </Form.Item>
      <Space>
        <Form.Item name="performer" valuePropName="checked">
          <Checkbox>{intl("performer")}</Checkbox>
        </Form.Item>
        <Form.Item name="rightSign" valuePropName="checked">
          <Checkbox>{intl("has-right-sign")}</Checkbox>
        </Form.Item>
      </Space>
      {/* {config &&
        config.map((item) => (
          <Form.Item label={intl(item.label)} name={item.name}>
            <Input />
          </Form.Item>
        ))} */}
    </>
  );
}
