// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_button__sRzXH {
  width: 100%;
  background-color: #5abab9;
  color: #ffffff;
}

.styles_button__sRzXH:not(:disabled):hover {
  background-color: #5abab9;
  color: #3f434e;
}`, "",{"version":3,"sources":["webpack://./src/components/ui/dropdown-buttons/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,yBAAA;EACA,cAAA;AACF;;AAEA;EACE,yBAAA;EACA,cAAA;AACF","sourcesContent":[".button {\r\n  width: 100%;\r\n  background-color: #5abab9;\r\n  color: #ffffff;\r\n}\r\n\r\n.button:not(:disabled):hover {\r\n  background-color: #5abab9;\r\n  color: #3f434e;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": `styles_button__sRzXH`
};
export default ___CSS_LOADER_EXPORT___;
