import styles from "./styles.module.scss";
import { useContext, useState } from "react";
import { useLocation } from "react-router-dom";

import { FileMenuProps } from "./types";
import { TranslationContext } from "../../../contexts/translation/index.context";
import { SessionContext } from "../../../contexts/session/index.context";
import { ModalContext } from "../../../contexts/modal/index.context";
import { request } from "../../../services";
import { useModals } from "./modal/utils/use-modals";
import { useFileInput } from "./modal/utils/use-file-input";
//Components
import Modal from "./modal";
//UI
import Space from "antd/lib/space";
import Dropdown from "antd/lib/dropdown";
import Button from "antd/lib/button";
// import Modal from "antd/lib/modal";
import Upload from "antd/lib/upload";
import Input from "antd/lib/input";
import Form from "antd/lib/form";
import Checkbox from "antd/lib/checkbox";
import message from "antd/lib/message";
import FormRef from "antd/lib/form";
import type { MenuProps } from "antd/es/menu/menu";
import type { UploadProps } from "antd/es/upload";
import type { FormProps } from "antd/es/form";
//Icons
import PlusOutlined from "@ant-design/icons/PlusOutlined";
import InboxOutlined from "@ant-design/icons/InboxOutlined";
import UploadOutlined from "@ant-design/icons/UploadOutlined";

const { Dragger } = Upload;

export default function FileMenuComponent(props: FileMenuProps) {
  const location = useLocation();
  const [form] = FormRef.useForm();
  const { intl } = useContext(TranslationContext);
  const { sessionData } = useContext(SessionContext);
  const { setRefreshFileEditor } = useContext(ModalContext);
  const [fileList, setFileList] = useState<any[]>([]);
  const [token, setToken] = useState("");
  const { file } = props;
  const { modalsState, showModal, hideModal } = useModals([
    "isModalOpen",
    "isKepInternalModalOpen",
    "isKepExternalModalOpen",
    "isEditInfo",
    "isScan",
  ]);
  const documentFile = useFileInput();
  const signatureFile = useFileInput();

  const info = () => {
    message.error(intl("data-is-not-deleted"));
  };

  const readFile = (file: any, isSignature = false) => {
    const reader = new FileReader();
    return new Promise((resolve, reject) => {
      reader.onloadend = () => {
        resolve({
          name: file.name,
          type: file.type,
          data: reader.result as string,
          isSignature,
        });
      };
      reader.onerror = reject;
      reader.readAsDataURL(file.originFileObj);
    });
  };

  const handleOk = async (modalName: string) => {
    hideModal(modalName);

    if (modalName === "isEditInfo") {
      await form?.validateFields();
      form?.submit();
      return;
    }

    let files: any[] = [];

    if (modalName === "isModalOpen") {
      files = await Promise.all(fileList.map((file) => readFile(file)));
    } else if (modalName === "isKepInternalModalOpen") {
      files = await Promise.all(fileList.map((file) => readFile(file, true)));
    } else if (modalName === "isKepExternalModalOpen") {
      const documentFiles = await Promise.all(
        documentFile.file.map((file) => readFile(file))
      );
      const signatureFiles = await Promise.all(
        signatureFile.file.map((file) => readFile(file, true))
      );

      files = [...documentFiles, ...signatureFiles];
    }

    const success = (
      await request(
        `${location.pathname.split("/").slice(0, 2).join("/")}/addfiles`,
        {
          method: "post",
          body: {
            documentId: sessionData?.bta_documents_id,
            page: location.pathname.split("/").pop(),
            signExternal: modalName === "isKepInternalModalOpen" ? 0 : 1,
            files,
          },
          cancelTokenKey: "addfiles",
        }
      )
    )?.success;

    if (!success) {
      info();
    }

    setFileList([]);
    if (modalName === "isKepExternalModalOpen") {
      documentFile.reset();
      signatureFile.reset();
    }
    setRefreshFileEditor(true);
  };

  const items: MenuProps["items"] = [
    {
      key: "1",
      label: (
        <Button
          className={styles["button"]}
          onClick={() => showModal("isModalOpen")}
        >
          {intl("add-file")}
        </Button>
      ),
    },
    {
      key: "2",
      label: (
        <Button
          className={styles["button"]}
          onClick={() => showModal("isKepInternalModalOpen")}
        >
          {intl("add-file-from-kep-internal")}
        </Button>
      ),
    },
    {
      key: "3",
      label: (
        <Button
          className={styles["button"]}
          onClick={() => showModal("isKepExternalModalOpen")}
        >
          {intl("add-file-from-kep-external")}
        </Button>
      ),
    },
    // {
    //   key: "4",
    //   label: (
    //     <Button className={styles["button"]}>
    //       {intl("add-from-template")}
    //     </Button>
    //   ),
    // },
    // {
    //   key: "5",
    //   label: <Button className={styles["button"]}>{intl("edit")}</Button>,
    // },
    {
      key: "6",
      label: (
        <Button
          className={styles["button"]}
          onClick={() => showModal("isEditInfo")}
          disabled={props.disabled}
        >
          {intl("edit-information")}
        </Button>
      ),
    },
    {
      key: "7",
      label: (
        <Button
          className={styles["button"]}
          onClick={() => {
            showModal("isScan");
            getIframeToken();
          }}
        >
          {intl("scan")}
        </Button>
      ),
    },
  ];

  const handleCancel = (modalName: string) => {
    hideModal(modalName);
    if (modalName === "isModalOpen" || modalName === "isKepInternalModalOpen") {
      setFileList([]);
    }
    if (modalName === "isKepExternalModalOpen") {
      documentFile.reset();
      signatureFile.reset();
    }
  };

  const handleChange = ({ file, fileList: newFileList }: any) => {
    if (file.status !== "uploading") {
      console.log(file, newFileList);
    }
    if (file.status === "done") {
      message.success(`${file.name} file uploaded successfully.`);
    } else if (file.status === "error") {
      message.error(`${file.name} file upload failed.`);
    }
    setFileList(newFileList);
  };

  const handleRemove = (file: any) => {
    setFileList((prevList) => prevList.filter((item) => item.uid !== file.uid));
  };

  const uploadProps: UploadProps = {
    name: "file",
    action: "http://localhost:3030/documents/addfiles",
    headers: {
      authorization: `Bearer ${localStorage.getItem("access_token")}`,
    },
    fileList,
    onChange: handleChange,
    onRemove: handleRemove,
    showUploadList: false,
  };

  const draggerProps: UploadProps = {
    ...uploadProps,
    multiple: true,
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };

  const directoryUploadProps: UploadProps = {
    ...uploadProps,
    directory: true,
  };

  const restrictedUploadProps = (
    file: any,
    errorMessage: string,
    isSignature: boolean
  ) => {
    const isValid = isSignature
      ? file.name.endsWith(".p7s")
      : !file.name.endsWith(".p7s");
    if (!isValid) {
      message.error(errorMessage);
      return Upload.LIST_IGNORE;
    }
    return true;
  };

  const kepInternalSignatureUploadProps: UploadProps = {
    ...uploadProps,
    beforeUpload: (file) =>
      restrictedUploadProps(file, intl("select-the-signature-file"), true),
    onChange: handleChange,
  };

  const kepExternalUploadProps: UploadProps = {
    ...uploadProps,
    beforeUpload: (file) =>
      restrictedUploadProps(file, intl("select-file-to-sign"), false),
    onChange: documentFile.handleFileChange,
  };

  const kepExternalSignatureUploadProps: UploadProps = {
    ...uploadProps,
    beforeUpload: (file) =>
      restrictedUploadProps(file, intl("select-the-signature-file"), true),
    onChange: signatureFile.handleFileChange,
  };

  const getIframeToken = async () => {
    try {
      const response = await request(
        `${location.pathname.split("/").slice(0, 2).join("/")}/gettoken`,
        {
          method: "post",
          body: {
            id: sessionData?.bta_documents_id,
            page: location.pathname.split("/").pop(),
          },
          cancelTokenKey: "gettoken",
        }
      );

      if (!response.success) {
        throw new Error("Failed to fetch token");
      }

      setToken(response.content);
      // return `http://192.168.2.44:3000/?token=${token}`;
    } catch (error) {
      console.error("Error fetching the token:", error);
      return "";
    }
  };

  type FieldType = {
    filename?: string;
    isOriginal?: boolean;
    isInternalUse?: boolean;
    isSendEmail?: boolean;
    notes?: string;
  };

  const onFinish: FormProps<FieldType>["onFinish"] = async (values) => {
    console.log("Success:", values);

    const filenameWithExtension = `${values.filename}${
      file?.fileName.split(/(?=\.\w+$)/)[1]
    }`;
    const success = (
      await request(
        `${location.pathname.split("/").slice(0, 2).join("/")}/updateFiles`,
        {
          method: "post",
          body: {
            documentId: sessionData?.bta_documents_id,
            page: location.pathname.split("/").pop(),
            fileId: file?.id,
            fileInfo: { ...values, filename: filenameWithExtension },
          },
          cancelTokenKey: "updateFiles",
        }
      )
    )?.success;

    if (!success) {
      info();
    }
    setRefreshFileEditor(true);
  };

  const onFinishFailed: FormProps<FieldType>["onFinishFailed"] = (
    errorInfo
  ) => {
    console.log("Failed:", errorInfo);
  };

  const modalsConfig = [
    {
      name: "isModalOpen",
      content: (
        <>
          <Dragger {...draggerProps}>
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">{intl("select-or-drag-file")}</p>
          </Dragger>
          {fileList.length > 0 && (
            <div>
              <Upload
                {...uploadProps}
                fileList={fileList}
                listType="text"
                showUploadList={{ showRemoveIcon: true }}
              />
            </div>
          )}
        </>
      ),
      footerButtons: (
        <>
          <Space>
            <Upload {...directoryUploadProps}>
              <Button icon={<UploadOutlined />}>
                {intl("add-files-from-folder")}
              </Button>
            </Upload>
          </Space>
          <Space>
            <Button key="cancel" onClick={() => handleCancel("isModalOpen")}>
              {intl("cancel")}
            </Button>
            <Button
              key="submit"
              type="primary"
              onClick={() => handleOk("isModalOpen")}
            >
              {intl("ok")}
            </Button>
          </Space>
        </>
      ),
    },
    {
      name: "isKepInternalModalOpen",
      content: (
        <>
          <Dragger {...kepInternalSignatureUploadProps}>
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">{intl("select-or-drag-file")}</p>
          </Dragger>
          {fileList.length > 0 && (
            <div>
              <Upload
                {...uploadProps}
                fileList={fileList}
                listType="text"
                showUploadList={{ showRemoveIcon: true }}
              />
            </div>
          )}
        </>
      ),
    },
    {
      name: "isKepExternalModalOpen",
      content: (
        <Space className={styles["space"]} direction="vertical">
          <div className={styles["space-div"]}>
            <Input
              placeholder={intl("select-the-document-file")}
              value={documentFile.fileName}
              readOnly
            />
            <Upload {...kepExternalUploadProps}>
              <Button icon={<UploadOutlined />} />
            </Upload>
          </div>
          <div className={styles["space-div"]}>
            <Input
              placeholder={intl("select-the-signature-file")}
              value={signatureFile.fileName}
              readOnly
            />
            <Upload {...kepExternalSignatureUploadProps}>
              <Button icon={<UploadOutlined />} />
            </Upload>
          </div>
        </Space>
      ),
    },
    {
      name: "isEditInfo",
      content: (
        <Form
          name="file-info"
          layout="vertical"
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          initialValues={{
            filename: file?.name_file_view.split(/(?=\.\w+$)/)[0],
            isOriginal: file?.isOriginal,
            isInternalUse: file?.IsInternalUse,
            isSendEmail: file?.isSendEmail,
            notes: file?.prim,
          }}
        >
          <Form.Item<FieldType>
            label={intl("file")}
            name="filename"
            // rules={[{ required: true, message: "Please input your username!" }]}
          >
            <Input addonAfter={file?.name_file_view.split(/(?=\.\w+$)/)[1]} />
          </Form.Item>
          <Form.Item<FieldType> name="isOriginal" valuePropName="checked">
            <Checkbox>{intl("original")}</Checkbox>
          </Form.Item>
          <Form.Item<FieldType> name="isInternalUse" valuePropName="checked">
            <Checkbox>{intl("for-internal-use")}</Checkbox>
          </Form.Item>
          <Form.Item<FieldType> name="isSendEmail" valuePropName="checked">
            <Checkbox>{intl("to-send")}</Checkbox>
          </Form.Item>
          <Form.Item<FieldType> label={intl("notes")} name="notes">
            <Input />
          </Form.Item>
        </Form>
      ),
    },
    {
      name: "isScan",
      content: (
        <iframe
          src={`https://scan.kai.ua/?token=${token}`}
          id="verify-widget"
          title="Verification Service"
          width="100%"
          height="300px"
          style={{ border: "none" }}
        />
      ),
    },
  ];

  return (
    <>
      <Dropdown menu={{ items }} trigger={["click"]} placement="bottomRight">
        <Button className={styles["menu-button"]}>
          <PlusOutlined />
        </Button>
      </Dropdown>
      {modalsConfig.map(({ name, content, footerButtons }) => (
        <Modal
          key={name}
          name={name}
          isOpen={modalsState[name]}
          onOk={() => handleOk(name)}
          onCancel={() => handleCancel(name)}
          footerButtons={footerButtons}
        >
          {content}
        </Modal>
      ))}
    </>
  );
}
