import styles from "./styles.module.scss";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { ColumnProps } from "./types";
import { request } from "../../../../services";
//UI
import Column from "@ant-design/plots/lib/components/column";

export default function ColumnComponent(props: ColumnProps) {
  const [data, setData] = useState([]);
  const location = useLocation();

  // useEffect(() => {
  //   fetchData();
  // }, []);

  // const fetchData = async () => {
  //   const content = (
  //     await request(`${location.pathname}/getinfociviltreatment`, {
  //       method: "get",
  //       cancelTokenKey: "getinfociviltreatment",
  //     })
  //   )?.content;
  //   setData(content || []);
  // };

  const dataColumn = props.data.map((item: any) => {
    let date = new Date(item.date_x);
    return { ...item, date_x: date.toLocaleDateString("uk-UA") };
  });

  const config = {
    data: dataColumn,
    xField: "date_x",
    yField: "x",
    seriesField: "name_x",
    // legend: { visible: false },
    isStack: true,
  };

  return <Column className={styles["chart"]} {...config} />;
}
