export const administrationData = [
  "configuring-page-access",
  "button-access-settings",
  "server-settings",
  "users",
];

export const documentCharacteristicsData = [
  "nomenclature-of-cases-current",
  "nomenclature-of-cases",
  "resolution-templates",
  "type-of-document",
  "document-index",
  "type-of-delivery",
];

export const hrDepartmentData = [
  "profession-classifier",
  "job-categories",
  "types-of-structural-unit",
  "employees",
  "structural-units",
  "positions",
  "employees-and-contact-persons",
];

export const generalData = ["correspondents"];
