import { createContext } from "react";
import { FormContextValue, FormProviderProps } from "./types";
import FormRef from "antd/lib/form";

export const FormContext = createContext<FormContextValue>({
  form: null,
});

export function FormProvider({ children }: FormProviderProps) {
  const [form] = FormRef.useForm();

  return (
    <FormContext.Provider value={{ form }}>{children}</FormContext.Provider>
  );
}
