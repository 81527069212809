import Axios, { AxiosInstance } from "axios";
import { api } from "./axios";
import { environment } from "./environment";
import User from "./user";

const cancelTokens = new Map<
  string,
  ReturnType<typeof Axios.CancelToken.source>
>();

interface RequestOptions {
  method: "get" | "post" | "put" | "patch" | "delete";
  body?: any;
  cancelTokenKey: string;
  adapter?: AxiosInstance;
  headers?: {
    "Content-Type"?: string;
  };
  responseType?:
    | "arraybuffer"
    | "blob"
    | "document"
    | "json"
    | "text"
    | "stream"
    | undefined;
}

/**
 * @param httpUrl - Request endpoint
 * @param httpOptions - Request options (key property is required)
 *
 * @returns {any | null} Server response or null
 */
export async function request(
  httpUrl: string,
  httpOptions: RequestOptions
): Promise<any> {
  console.time(httpOptions.cancelTokenKey + "::request");

  if (cancelTokens.has(httpOptions.cancelTokenKey)) {
    cancelTokens.get(httpOptions.cancelTokenKey)!.cancel();
  }

  cancelTokens.set(httpOptions.cancelTokenKey, Axios.CancelToken.source());

  const httpHeaders = {
    headers: { authorization: "Bearer " + User.token },
    cancelToken: cancelTokens.get(httpOptions.cancelTokenKey)!.token,
    responseType: httpOptions.responseType,
  };

  try {
    let response = null;
    let adapter = api;

    if (httpOptions.adapter) {
      adapter = httpOptions.adapter;
    }

    if (httpOptions.method === "get") {
      response = await adapter[httpOptions.method](httpUrl, httpHeaders);
    } else {
      response = await adapter[httpOptions.method](
        httpUrl,
        httpOptions.body,
        httpHeaders
      );
    }

    if (environment.development) {
      console.timeLog(response.data);
    }

    if (response.headers["content-type"] === "application/zip") {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "documents.zip");
      document.body.appendChild(link);
      link.click();
      return;
    }

    if (response.data?.success) {
      return response.data;
    }

    if (environment.development && response.data?.error) {
      console.timeLog(response.data.error);
    }

    return /* null */ { success: false };
  } catch (exception) {
    if (environment.development) {
      if (exception instanceof Error) {
        console.timeLog(exception.message);
      } else {
        console.log(exception);
      }
    }

    return null;
  } finally {
    console.timeEnd(httpOptions.cancelTokenKey + "::request");
  }
}
