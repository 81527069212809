import { useState } from "react";

export const useFileInput = () => {
  const [file, setFile] = useState<any[]>([]);
  const [fileName, setFileName] = useState<string>("");

  const handleFileChange = ({ file, fileList: newFileList }: any) => {
    if (file.status === "uploading") {
      setFileName(file.name);
    }
    setFile(newFileList);
  };

  const reset = () => {
    setFile([]);
    setFileName("");
  };

  return { file, fileName, handleFileChange, reset };
};
