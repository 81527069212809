import { FormInstance } from "antd";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";

import { MergedColumnType } from "../../../../../../ui/table/utils/types";
//Locale
import "dayjs/locale/uk";

dayjs.extend(customParseFormat);
dayjs.locale("uk");

const setCommonFieldValues = (
  form: FormInstance,
  rowData: MergedColumnType
) => {
  form.setFieldsValue({
    code: rowData.Code,
    name: rowData.Name,
    notes: rowData.Notes,
  });
};

export const setDocumentChar: Record<
  string,
  (form: FormInstance, rowData: MergedColumnType) => void
> = {
  "/directories/nomenclature-of-cases-current": (form, rowData) => {
    form.setFieldsValue({
      structuralUnit: rowData.sta_otdel_id,
      numNomenclature: rowData.ReestrNum,
      year: rowData.ReestrDate,
      from: rowData.ApprovalDate && dayjs(rowData.ApprovalDate, "DD.MM.YYYY"),
      valid: rowData.isActive,
      document: rowData.ApprovalDoc,
      approvalNotes: rowData.ApprovalDescription,
      notes: rowData.Description,
      addNum: rowData.isAddNum,
      addC: rowData.isAddC,
    });
  },
  "/directories/nomenclature-of-cases": (form, rowData) => {
    form.setFieldsValue({
      nomenclatureOfCasesCurrent: rowData.eta_OfficialDocYear_id,
      index: rowData.Code_OfficialDoc,
      heading: rowData.Name_OfficialDoc,
      shelfLife: rowData.LimitationText,
      notes: rowData.Prim_OfficialDoc,
    });
  },
  "/directories/resolution-templates": (form, rowData) => {
    form.setFieldsValue({
      text: rowData.text_rezoluzia,
      author: rowData.sta_clerks_id,
      notDisplayListPerformers: rowData.IsNotExecTextRez,
    });
  },
  "/directories/type-of-document": (form, rowData) => {
    setCommonFieldValues(form, rowData);
    form.setFieldsValue({
      incoming: rowData.isEnterDoc,
      outcoming: rowData.isTurnDoc,
      internal: rowData.isInterDoc,
    });
  },
  "/directories/document-index": setCommonFieldValues,
  "/directories/type-of-delivery": setCommonFieldValues,
};
