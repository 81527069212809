// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_list__r6uRS {
  padding: 0 20px;
}
.styles_list__r6uRS .ant-list-header {
  padding-block: inherit;
  color: #234261;
}
.styles_list__r6uRS .ant-list-item {
  border-block-end: inherit;
  padding: 5px 0;
  font-weight: 600;
}

.styles_link__Zxu76 {
  color: rgba(0, 0, 0, 0.88);
}`, "",{"version":3,"sources":["webpack://./src/components/ui/list/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;AACF;AACI;EACE,sBAAA;EACA,cAAA;AACN;AACI;EACE,yBAAA;EACA,cAAA;EACA,gBAAA;AACN;;AAIA;EACE,0BAAA;AADF","sourcesContent":[".list {\r\n  padding: 0 20px;\r\n  :global {\r\n    .ant-list-header {\r\n      padding-block: inherit;\r\n      color: #234261;\r\n    }\r\n    .ant-list-item {\r\n      border-block-end: inherit;\r\n      padding: 5px 0;\r\n      font-weight: 600;\r\n    }\r\n  }\r\n}\r\n\r\n.link {\r\n  color: rgba(0, 0, 0, 0.88);\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"list": `styles_list__r6uRS`,
	"link": `styles_link__Zxu76`
};
export default ___CSS_LOADER_EXPORT___;
