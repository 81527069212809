import styles from "./styles.module.scss";
import { useContext } from "react";
import { useLocation } from "react-router-dom";

import { ListProps } from "./types";
import { TranslationContext } from "../../../contexts/translation/index.context";
//UI
import List from "antd/lib/list";
import Typography from "antd/lib/typography";

const { Title } = Typography;

export default function ListComponent(props: ListProps) {
  const location = useLocation();
  const { intl } = useContext(TranslationContext);

  return (
    <List
      className={styles["list"]}
      header={<Title level={3}>{props.title && intl(props.title)}</Title>}
      dataSource={props.data}
      renderItem={(item) => (
        <List.Item>
          {
            <a
              className={styles["link"]}
              href={`${location.pathname}/${item}`}
              // target="_blank"
              rel="noopener noreferrer"
            >
              {intl(item)}
            </a>
          }
        </List.Item>
      )}
    />
  );
}
