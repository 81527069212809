import { useEffect, useState } from "react";

import { FloatButtonProps } from "./types";
//UI
import FloatButton from "antd/lib/float-button";
//Icons
import ArrowUpOutlined from "@ant-design/icons/ArrowUpOutlined";

export default function FloatButtonComponent(props: FloatButtonProps) {
  const [isVisible, setIsVisible] = useState(false);
  const { tableRef } = props;

  const toggleVisibility = () => {
    if (tableRef.current && tableRef.current.scrollTop > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const scrollToTop = () => {
    if (tableRef.current) {
      tableRef.current.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    const tableElement = tableRef.current;
    if (tableElement) {
      tableElement.addEventListener("scroll", toggleVisibility);
      return () => tableElement.removeEventListener("scroll", toggleVisibility);
    }
  }, []);

  return isVisible ? (
    <FloatButton icon={<ArrowUpOutlined />} onClick={scrollToTop} />
  ) : null;
}
