import styles from "./styles.module.scss";
import { useState } from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";

import MainLayout from "../../hoc/main-layout";
import * as Actions from "../../store/actions";
import { translate } from "../../translation";
import { PropsTypes } from "../types";
import { StateTypes } from "../../store/types";
import { request } from "../../services";
import User from "../../services/user";
import { generatePassword } from "../../services/generate-password";
//Components
import Modal from "../../components/ui/modal";
import CreateFolderForm from "../../components/forms/profile";
//UI
import Space from "antd/lib/space";
import Form from "antd/lib/form";
import Input from "antd/lib/input";
import Button from "antd/lib/button";
import Typography from "antd/lib/typography";
import Tooltip from "antd/lib/tooltip";
import FormRef from "antd/lib/form";
//Icons
import KeyOutlined from "@ant-design/icons/KeyOutlined";

const { Title } = Typography;

function Profile(props: PropsTypes) {
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const [form] = FormRef.useForm();
  const currentUser = User.decode();
  const fullName = `${currentUser?.clerk[0].lastName} ${
    currentUser?.clerk[0].firstName
  } ${currentUser?.clerk[0].middleName ?? ""}`;

  const intl = (selector: string) => {
    return translate(selector, props.lang ?? "uk");
  };

  const onGeneratePassword = () => {
    const newPassword = generatePassword();
    form.setFieldsValue({ newPassword: newPassword });
  };

  const onFinish = async (values: any) => {
    console.log("Success:", values);
    setLoading(true);
    await request(`${location.pathname}/updatepassword`, {
      method: "post",
      body: values,
      cancelTokenKey: "updatepassword",
    });
    setLoading(false);
  };

  const onFinishFailed = async (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <MainLayout activeLink={window.location.pathname}>
      <Space className={styles["profile-space"]} direction="vertical">
        <Modal okText={intl("ok")} name={intl("create-folder")} form={form}>
          <CreateFolderForm intl={intl} url={location.pathname} form={form} />
        </Modal>
        <Space className={styles["forms-space"]} align="start" size={"large"}>
          <Space direction="vertical">
            <Title level={4}>{intl("personal-info")}</Title>
            <Form
              name="info-form"
              layout="vertical"
              // onFinish={onInfoFinish}
              // onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <Form.Item
                name="login"
                label={intl("login")}
                initialValue={currentUser?.login}
              >
                <Input type="text" disabled={true} />
              </Form.Item>
              <Form.Item
                name="fullName"
                label={intl("full-name-pib")}
                initialValue={fullName}
              >
                <Input type="text" disabled={true} />
              </Form.Item>
              <Form.Item
                name="email"
                label={intl("mail")}
                initialValue={currentUser?.email}
              >
                <Input type="text" disabled={true} />
              </Form.Item>

              <Form.Item label={intl("role")}>
                <Input
                  defaultValue={currentUser?.role[0].roleName}
                  disabled={true}
                />
              </Form.Item>
            </Form>
          </Space>
          <Space direction="vertical">
            <Title level={4}>{intl("update-password")}</Title>
            <Form
              className={styles["form"]}
              name="password-form"
              layout="vertical"
              form={form}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <Form.Item
                name="currentPassword"
                label={intl("current-password")}
                rules={[
                  {
                    required: true,
                    message: intl("current-password-required"),
                  },
                ]}
              >
                <Input.Password />
              </Form.Item>
              <Space align="start">
                <Form.Item
                  className={styles["new-password"]}
                  name="newPassword"
                  label={intl("password")}
                  rules={[
                    {
                      required: true,
                      message: intl("password-required"),
                    },
                    {
                      pattern:
                        /(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z!@#$%^&*_]{8,}/g,
                      message: intl("input-correct-password"),
                    },
                  ]}
                  validateTrigger="onSubmit"
                >
                  <Input.Password />
                </Form.Item>
                <Form.Item label=" ">
                  <Tooltip title={intl("generate-password")}>
                    <Button onClick={onGeneratePassword}>
                      <KeyOutlined />
                    </Button>
                  </Tooltip>
                </Form.Item>
              </Space>
              <Form.Item
                name="confirmNewPassword"
                label={intl("confirm-password")}
                rules={[
                  {
                    required: true,
                    message: intl("confirm-password-required"),
                  },
                  {
                    pattern:
                      /(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z!@#$%^&*_]{8,}/g,
                    message: intl("input-correct-password"),
                  },
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      if (!value || getFieldValue("newPassword") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(intl("confirm-password-reference"));
                    },
                  }),
                ]}
                validateTrigger="onSubmit"
              >
                <Input.Password />
              </Form.Item>

              <Form.Item>
                <Button
                  className={styles["button"]}
                  htmlType="submit"
                  loading={loading}
                >
                  {intl("update-data")}
                </Button>
              </Form.Item>
            </Form>
          </Space>
        </Space>
      </Space>
    </MainLayout>
  );
}

function mapStateToProps(state: StateTypes) {
  return {
    lang: state.lang?.value,
    message: state.authLayout?.value,
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    changeLanguage: (language: string) =>
      dispatch({ type: Actions.CHANGE_LANGUAGE, language }),
    closeMessage: () => dispatch({ type: Actions.CLOSE_MESSAGE }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
