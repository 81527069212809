import type { ColumnType } from "antd/es/table";
import { SortOrder } from "antd/es/table/interface";

export const createColumn = <T>(
  title: React.ReactNode,
  dataIndex: keyof T,
  width?: string | number,
  getColumnSearchProps?: (dataIndex: keyof T) => ColumnType<T>,
  sorter?: (a: T, b: T) => number,
  render?: (text: string, record: T) => React.ReactNode,
  isFilter?: boolean,
  data?: T[]
) => {
  const column = {
    title,
    dataIndex,
    key: dataIndex,
    align: "left" as const,
    width,
    ellipsis: true,
    ...getColumnSearchProps?.(dataIndex),
    sorter,
    sortDirections: ["descend", "ascend"] as SortOrder[],
    render,
  };

  if (isFilter && data) {
    const uniqueValues = Array.from(new Set(data.map(item => item[dataIndex])));
    column.filters = uniqueValues.sort((a, b) => String(a).localeCompare(String(b))).map(value => ({ text: String(value), value: String(value) }));
    column.onFilter = (value: any, record: T) => record[dataIndex] === value;
  }

  return column;
};
