import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { FormInstance } from "antd";

import { MergedColumnType } from "../../../../../ui/table/utils/types";
//Locale
import "dayjs/locale/uk";

dayjs.extend(customParseFormat);
dayjs.locale("uk");

export const setAdministration: Record<
  string,
  (form: FormInstance, rowData: MergedColumnType) => void
> = {
  "/administration/configuring-page-access": (form, rowData) => {
    form.setFieldsValue({
      role: { value: rowData.roleId, label: rowData.Role },
      pages: [{ value: rowData.routeId, label: rowData.Pages }],
      access: rowData.Access,
    });
  },
  "/administration/button-access-settings": (form, rowData) => {
    form.setFieldsValue({
      role: { value: rowData.roleId, label: rowData.Role },
      pages: [{ value: rowData.routeId, label: rowData.Pages }],
      buttons: [{ value: rowData.buttonId, label: rowData.Buttons }],
      access: rowData.Access,
    });
  },
  "/administration/server-settings": (form, rowData) => {
    form.setFieldsValue({
      serverName: rowData.ServerName,
      serverCode: rowData.ServerCode,
      imapHost: rowData.ImapHost,
      imapPort: rowData.ImapPort,
    });
  },
  "/administration/users": (form, rowData) => {
    form.setFieldsValue({
      fullName: rowData.clerksId,
      structuralUnit: `${rowData.NameDepartment ?? ""}\\${
        rowData.NamePost ?? ""
      }`,
      login: rowData.UserName,
      email: rowData.email,
      role: { value: rowData.roleId, label: rowData.Name_prav },
      webVersion: rowData.WebAdd,
      allowAccess: rowData.del,
      allowAccessHidden: rowData.del,
    });
  },
};
