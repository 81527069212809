// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_radio-group__AuGTC {
  padding: 0.5rem 0.5rem 0;
}`, "",{"version":3,"sources":["webpack://./src/components/ui/radio-group/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,wBAAA;AACF","sourcesContent":[".radio-group {\r\n  padding: 0.5rem 0.5rem 0;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"radio-group": `styles_radio-group__AuGTC`
};
export default ___CSS_LOADER_EXPORT___;
