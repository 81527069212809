// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_form__vUZCB {
  max-width: 600px;
  width: 600px;
  background-color: #ffffff;
  padding: 24px 50px 10px;
  margin-top: 1rem;
}
.styles_form__vUZCB .ant-form-item .ant-form-item-label > label {
  font-size: 16px;
  color: #767d83;
}

.styles_button__WnjnC {
  background-color: #5abab9;
  height: auto;
  padding: 5px 50px;
  font-size: 16px;
}

.styles_button__WnjnC:not(:disabled):hover {
  background-color: #5abab9;
  color: #3f434e;
}

.styles_button__WnjnC:focus-visible {
  outline: none;
}

.styles_return__hHemG {
  color: #4a7db1;
  font-size: 16px;
}`, "",{"version":3,"sources":["webpack://./src/pages/auth/password-recovery/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,YAAA;EACA,yBAAA;EACA,uBAAA;EACA,gBAAA;AACF;AACI;EACE,eAAA;EACA,cAAA;AACN;;AAIA;EACE,yBAAA;EACA,YAAA;EACA,iBAAA;EACA,eAAA;AADF;;AAIA;EACE,yBAAA;EACA,cAAA;AADF;;AAIA;EACE,aAAA;AADF;;AAIA;EACE,cAAA;EACA,eAAA;AADF","sourcesContent":[".form {\r\n  max-width: 600px;\r\n  width: 600px;\r\n  background-color: #ffffff;\r\n  padding: 24px 50px 10px;\r\n  margin-top: 1rem;\r\n  :global {\r\n    .ant-form-item .ant-form-item-label > label {\r\n      font-size: 16px;\r\n      color: #767d83;\r\n    }\r\n  }\r\n}\r\n\r\n.button {\r\n  background-color: #5abab9;\r\n  height: auto;\r\n  padding: 5px 50px;\r\n  font-size: 16px;\r\n}\r\n\r\n.button:not(:disabled):hover {\r\n  background-color: #5abab9;\r\n  color: #3f434e;\r\n}\r\n\r\n.button:focus-visible {\r\n  outline: none;\r\n}\r\n\r\n.return {\r\n  color: #4a7db1;\r\n  font-size: 16px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": `styles_form__vUZCB`,
	"button": `styles_button__WnjnC`,
	"return": `styles_return__hHemG`
};
export default ___CSS_LOADER_EXPORT___;
